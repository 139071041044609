import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../components/Navbar";
import { analytics } from "../firebase";
import { database } from "../firebase";
import axios from "axios";
import { ThreeBounce } from "better-react-spinkit";
import landing_img from "../assets/image/LandingPageImage.jpg";

const LandingPage = () => {
  const history = useHistory();
  const [indicator, setIndicator] = useState(false);
  const [name1, setName1] = useState("")
  const [profession, setProfession] = useState("")
  const [city, setCity] = useState("")
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    analytics.logEvent('landing_page');
    setError("")
  }, []);

  const onChangeHandler = (event) => {
    setError("")
    const { name, value } = event.currentTarget;
    if (name === "name1") {
      setName1(value)
    } else if (name === "profession") {
      setProfession(value)
    } else if (name === "city") {
      setCity(value)
    } else if (name === "email") {
      setEmail(value)
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setIndicator(true);
      const postKey = database.ref().child("CT").push().key;
      database
        .ref(`CT/${postKey}`)
        .set({
          CandidateId: postKey,
          Name: name1,
          profession: profession,
          City: city,
          Email: email,
        })
        .then((res) => {
          const msg = {
            personalizations: [
              {
                from: {
                  email: "rey@subworknow.com",
                  name: "SubWorkNow",
                },
                to: [
                  {
                    email: "rey@subworknow.com",
                    // email: "regina.rombangok@gmail.com",
                    name: "SubWorkNow",
                  },
                ],
              },
            ],
            from: {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
            reply_to: {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
            subject: "You have a new message from a SC candidate",
            content: [
              {
                type: "text/html",
                value: `<!doctype html>
                <html>
                  <body>
                    <p>Hi Rey, You have a SC candidate data now.</p>
                    <p>Name: ${name1}</p>
                    <p>Profession: ${profession}</p>
                    <p>City: ${city}</p>
                    <p>Email: ${email}</p>
                  </body>
                </html>
              `,
              },
            ],
          };

          axios
            .post(
              "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
              msg,
              { headers: { "Content-Type": "application/json" } }
            )
            .then(function (response) {
              setIndicator(false)
              setError("")
              history.push("/landingsuccess");
            })
            .catch(function (error) {
              setIndicator(false)
              setError(error)
            });
        })
        .catch((err) => {
          setIndicator(false)
          setError(err)
        });
    } catch (error) {
      setIndicator(false)
      setError(error)
    }
  }

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center pb-8 bg-landing">
        <img
          src={landing_img}
          alt="..."
          className="shadow max-w-full w-full h-auto align-middle border-none"
        />
        {indicator && <ThreeBounce size={16} color="#03a9f4" />}
        <h1 className="text-center mt-14 md:mt-24 font-bold text-4xl md:text-8xl px-10">Welcome!</h1>
        <p className="text-center mt-8 font-bold text-xl md:text-4xl w-80 md:w-96 italic">Complete Below to be Notified about Open Jobs & Projects!</p>
        <div className="mt-14 w-80 md:w-96">
          <div className="flex justify-between items-center mb-3">
            <p className="w-24">Name</p>
            <input 
              className="w-60 p-2 border rounded" 
              name="name1"
              value={name1}
              placeholder=""
              id="name1"
              onChange={(event) => onChangeHandler(event)}/>
          </div>
          <div className="flex justify-between items-center mb-3">
            <p className="w-24">Profession</p>
            <input 
              className="w-60 p-2 border rounded"
              name="profession"
              value={profession}
              placeholder=""
              id="profession"
              onChange={(event) => onChangeHandler(event)}/>
          </div>
          <div className="flex justify-between items-center mb-3">
            <p className="w-24">City</p>
            <input 
              className="w-60 p-2 border rounded"
              name="city"
              value={city}
              placeholder=""
              id="city"
              onChange={(event) => onChangeHandler(event)}/>
          </div>
          <div className="flex justify-between items-center mb-3">
            <p className="w-24">Email</p>
            <input 
              className="w-60 p-2 border rounded"
              name="email"
              value={email}
              placeholder=""
              id="email"
              onChange={(event) => onChangeHandler(event)}/>
          </div>
        </div>
        <div className="mt-10 md:mt-14">
          <button 
            className={`${name1 && profession && city && email ? "bg-primary" : "bg-gray-500"} text-white p-2 rounded w-40`}
            onClick={(event) => {
              onSubmit(event);
            }}
            disabled={!name1 || !profession || !city || !email}>
            Submit
          </button>
        </div>
        { !!error &&
          <p className="text-center mt-5 md:mt-10 font-300 text-base md:text-lg px-10">SubWorkNow.com will notify your email of applicable jobs and projects in your area.</p>        
        }
      </div>
    </div>
  );
};

export default LandingPage;
