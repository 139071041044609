import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { analytics, auth, database } from "../firebase";
import OneSignal from "react-onesignal";
import axios from "axios";
import { FadingCircle } from "better-react-spinkit";

const SignUp = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [type, setType] = useState("gc");
  const [error, setError] = useState(null);
  const [indicator, setIndicator] = useState(false);

  const createUserWithEmailAndPasswordHandler = async (
    event,
    email,
    password,
    confirmPwd
  ) => {
    event.preventDefault();
    if (type === 'gc') {
      analytics.logEvent('gc_signup_button');
    } else if (type === 'sc') {
      analytics.logEvent('sc_signup_button');
    } else if (type === "r") {
      analytics.logEvent('r_signup_button');
    }

    let playerId = "";
    try {
      playerId = await OneSignal.getPlayerId();
    } catch (err) {
      playerId = "";
    }

    if (password !== confirmPwd) {
      setError("Passwords don't match.");
      return;
    }

    try {
      setIndicator(true);
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      setError(null);

      if (type === "gc") {
        const gcUserRef = database.ref(`GCT/${user.uid}`);
        await gcUserRef.set({
          Email: email,
          UserUid: user.uid,
          PlayerId: playerId,
          JoinedAt: (new Date()).toISOString(),
        });
      } else if (type === "sc") {
        const scUserRef = database.ref(`SCT/${user.uid}`);
        await scUserRef.set({
          Email: email,
          UserUid: user.uid,
          PlayerId: playerId,
          JoinedAt: (new Date()).toISOString(),
        });
      } else if (type === "r") {
        const rUserRef = database.ref(`RT/${user.uid}`);
        await rUserRef.set({
          Email: email,
          UserUid: user.uid,
          PlayerId: playerId,
          JoinedAt: (new Date()).toISOString(),
        });
      }

      const msg = {
        personalizations: [
          {
            from: {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
            to: [
              {
                email: email,
                name: "",
              },
            ],
          },
        ],
        from: {
          email: "rey@subworknow.com",
          name: "SubWorkNow",
        },
        reply_to: {
          email: "rey@subworknow.com",
          name: "SubWorkNow",
        },
        subject: "Congratulations",
        content: [
          {
            type: "text/html",
            value: `<!doctype html>
              <html>
                <body>
                  <div style="display: flex; align-items: center;">
                    <img src="https://i.ibb.co/b5WpDdv/logo.png" alt="logo" border="0">
                    <p style="font-weight: bold; margin-left: 8px; color: black;">SubWorkNow</p>
                  </div>
                  <p>Welcome to SubWorkNow.com.</p>
                  <p>This email confirms your Registration. If you did not register or have any questions feel free to contact us at 1-844-SUBWORK1 (1-844-782-6691) or rey@subworknow.com</p>
                </body>
              </html>
            `,
          },
        ],
      };

      axios
        .post(
          "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
          msg,
          { headers: { "Content-Type": "application/json" } }
        )
        .then(function (response) {
          setTimeout(() => setIndicator(false), 500);
          history.push("/profile");
        })
        .catch(function (error) {
          setTimeout(() => setIndicator(false), 500);
          history.push("/profile");
        });
    } catch (error) {
      setIndicator(false);
      setError("Error Signing up with email and password");
    }

    setEmail("");
    setPassword("");
    setConfirmPwd("");
    setType("gc");
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "type") {
      setType(value);
    } else if (name === "confirmPwd") {
      setConfirmPwd(value);
    }
  };

  return (
    <div className="mt-8">
      <h1 className="text-3xl mb-2 text-center font-bold">Sign Up</h1>
      <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        {error !== null && (
          <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <form className="">
          <label htmlFor="userEmail" className="block">
            Email:
          </label>
          <input
            type="email"
            className="my-1 p-1 w-full border border-black-400"
            name="userEmail"
            value={email}
            placeholder="Your Email"
            id="userEmail"
            onChange={(event) => onChangeHandler(event)}
          />
          <label htmlFor="userPassword" className="block">
            Password:
          </label>
          <input
            type="password"
            className="mt-1 p-1 w-full border border-black-400"
            name="userPassword"
            value={password}
            placeholder="Your Password"
            id="userPassword"
            onChange={(event) => onChangeHandler(event)}
          />
          <label htmlFor="confirmPassword" className="block">
            Confirm Password:
          </label>
          <input
            type="password"
            className="mt-1 p-1 w-full border border-black-400"
            name="confirmPwd"
            value={confirmPwd}
            placeholder="Confirm Password"
            id="confirmPwd"
            onChange={(event) => onChangeHandler(event)}
          />
          <label htmlFor="userType" className="block">
            Type:
          </label>
          <div className="mt-1 mb-5 w-full">
            <input
              type="radio"
              id="gc"
              name="type"
              value="gc"
              defaultChecked
              onChange={(event) => onChangeHandler(event)}
            ></input>
            <label htmlFor="gc" className="ml-1">
              General Contractor
            </label>
            <input
              type="radio"
              id="sc"
              name="type"
              value="sc"
              className="ml-5"
              onChange={(event) => onChangeHandler(event)}
            ></input>
            <label htmlFor="sc" className="ml-1">
              Sub-Contractor
            </label>
            <input
              type="radio"
              id="r"
              name="type"
              value="r"
              className="ml-5"
              onChange={(event) => onChangeHandler(event)}
            ></input>
            <label htmlFor="r" className="ml-1">
              Realtor
            </label>
          </div>
          <button
            className="flex justify-center items-center bg-primary w-full py-2 text-white text-center h-10"
            onClick={(event) => {
              createUserWithEmailAndPasswordHandler(
                event,
                email,
                password,
                confirmPwd
              );
            }}
            disabled={indicator}
          >
            {indicator ? <FadingCircle size={16} color="#03a9f4" /> : "Sign up"}
          </button>
        </form>
        <p className="text-center my-3">
          Already have an account?{" "}
          <Link to="/" className="text-primary">
            Sign in here
          </Link>
        </p>
      </div>
    </div>
  );
};
export default SignUp;
