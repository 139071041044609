import React, {useEffect} from "react";
import { analytics } from "../firebase";
import axios from "axios";

const EmailNotifyPage = () => {
  useEffect(() => {
    const value = window.location.href;
    console.log("value=>", value);
    const temp = value.split("/");
    const clickedEmail = temp[temp.length - 1];
    console.log("clcked email=>", clickedEmail);
    analytics.logEvent('email_notify');
    const msg = {
      personalizations: [
        {
          from: {
            email: "rey@subworknow.com",
            name: "SubWorkNow",
          },
          to: [
            {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
          ],
        },
      ],
      from: {
        email: "rey@subworknow.com",
        name: "SubWorkNow",
      },
      subject: "Notification!",
      content: [
        {
          type: "text/html",
          value: `<!doctype html>
          <html>
            <body>
              <p>You can add a profile of ${clickedEmail} as an SC manually.</p>
            </body>
          </html>
        `,
        },
      ],
    };

    axios
      .post(
        "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
        msg,
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function (response) {
      })
      .catch(function (error) {
      });

  }, []);

  return (
    <div className="w-full">
      <p className="text-center mt-72 font-bold text-xl">SubWorkNow.com will Send You an Email when Applicable Jobs/Projects Appear</p>
    </div>
  );
};

export default EmailNotifyPage;
