import React from "react";

const LandingSuccessPage = () => {
  return (
    <div className="w-full">
      <p className="text-center mt-72 font-bold text-xl">SubWorkNow.com will notify your email of applicable jobs and projects in your area.</p>
    </div>
  );
};

export default LandingSuccessPage;
