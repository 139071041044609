import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { analytics, database } from "../firebase";
import avatar from "../assets/image/avatar.png";

const ViewSCPage = () => {
  const {
    state: { bid },
  } = useLocation();
  const history = useHistory();

  const [licenseNumber, setLicenseNumber] = useState("");
  const [legalEntityName, setLegalEntityName] = useState("");
  const [pointOfContact, setPointOfContact] = useState("");
  const [secondEmail, setSecondEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [references, setReferences] = useState("");
  const [location, setLocation] = useState("San Antonio, TX Metro Area");
  const [icon, setIcon] = useState("");
  const [bio, setBio] = useState("");
  const [sampleImg1, setSampleImg1] = useState("");
  const [sampleImg2, setSampleImg2] = useState("");
  const [sampleImg3, setSampleImg3] = useState("");

  useEffect(() => {
    async function fetchData() {
      database.ref("SCT/" + bid.UserUid).once("value", (snapshot) => {
        let icon = snapshot.val().IconUpload;
        let licenseNumber = snapshot.val().LicenseNumber;
        let legalEntityName = snapshot.val().LegalEntityName;
        let pointOfContact = snapshot.val().PointOfContact;
        let secondEmail = snapshot.val().SecondEmail;
        let phone = snapshot.val().Phone;
        let website = snapshot.val().Website;
        let socialMedia = snapshot.val().SocialMedia;
        let references = snapshot.val().References;
        let location = snapshot.val().Location;
        let bio = snapshot.val().Bio;
        let sampleImg1 = snapshot.val().SampleImg1;
        let sampleImg2 = snapshot.val().SampleImg2;
        let sampleImg3 = snapshot.val().SampleImg3;
        setIcon(icon);
        setLicenseNumber(licenseNumber);
        setLegalEntityName(legalEntityName);
        setPointOfContact(pointOfContact);
        setSecondEmail(secondEmail);
        setPhone(phone);
        setWebsite(website);
        setSocialMedia(socialMedia);
        setReferences(references);
        setLocation(location);
        setBio(bio);
        setSampleImg1(sampleImg1);
        setSampleImg2(sampleImg2);
        setSampleImg3(sampleImg3);
      });
    }

    analytics.logEvent('view_sc_page');
    fetchData();
    // eslint-disable-next-line
  }, []);

  const onPress = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <div className="w-full flex flex-col">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        <img
          src={icon ? icon : avatar}
          alt="..."
          className="w-32 h-32 rounded-full"
        />
      </div>
      <div className="mx-auto w-11/12 md:w-2/4 rounded py-4 px-4 md:px-8 flex flex-col">
        <label htmlFor="licenseNumber" className="block text-sm">
          License Number:
        </label>
        <input
          type="text"
          className="my-2 p-1 w-full border border-black-400"
          name="licenseNumber"
          value={licenseNumber}
          placeholder=""
          id="licenseNumber"
          readOnly
        />
        <label htmlFor="legalEntity" className="block text-sm">
          Legal Entity Name:
        </label>
        <input
          type="text"
          className="my-2 p-1 w-full border border-black-400"
          name="legalEntity"
          value={legalEntityName}
          placeholder=""
          id="legalEntity"
          readOnly
        />
        <label htmlFor="pointOfContact" className="block text-sm">
          Point Of Contact:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="pointOfContact"
          value={pointOfContact}
          placeholder=""
          id="pointOfContact"
          readOnly
        />
        <label htmlFor="secondEmail" className="block text-sm">
          Second Email Address:
        </label>
        <input
          type="email"
          className="my-1 p-1 w-full border border-black-400"
          name="secondEmail"
          value={secondEmail}
          placeholder=""
          id="secondEmail"
          readOnly
        />
        <label htmlFor="phone" className="block text-sm">
          Phone Number:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="phone"
          value={phone}
          placeholder=""
          id="phone"
          readOnly
        />
        <label htmlFor="website" className="block text-sm">
          Website:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="website"
          value={website}
          placeholder=""
          id="website"
          readOnly
        />
        <label htmlFor="socialMedia" className="block text-sm">
          Social Media:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="socialMedia"
          value={socialMedia}
          placeholder=""
          id="socialMedia"
          readOnly
        />
        {bio && (
          <>
            <label htmlFor="bio" className="block text-sm">
              Bio / Resume:
            </label>
            <div className="flex justify-center mt-3">
              <img
                src={bio}
                alt="..."
                className="border rounded-lg h-40 w-40"
              />
              <button
                className={`w-40 bg-primary text-white py-2 my-3 mx-3 rounded-full focus:outline-none self-center`}
                onClick={(event) => {
                  history.push({
                    pathname: "viewimage",
                    state: { image: bio },
                  });
                }}
              >
                View Bio / Resume
              </button>
            </div>
          </>
        )}
        <label htmlFor="references" className="block text-sm">
          References:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="references"
          value={references}
          placeholder=""
          id="references"
          readOnly
        />
        {sampleImg1 && (
          <>
            <label htmlFor="sampleImg1" className="block text-sm">
              Sample Work Image 1:
            </label>
            <div className="flex justify-center mt-3">
              <img
                src={sampleImg1}
                alt="..."
                className="border rounded-lg h-40 w-40"
              />
              <button
                className={`w-56 bg-primary text-white py-2 my-3 mx-3 rounded-full focus:outline-none self-center`}
                onClick={(event) => {
                  history.push({
                    pathname: "viewimage",
                    state: { image: sampleImg1 },
                  });
                }}
              >
                View Sample Work Image 1
              </button>
            </div>
          </>
        )}
        {sampleImg2 && (
          <>
            <label htmlFor="sampleImg1" className="block text-sm">
              Sample Work Image 2:
            </label>
            <div className="flex justify-center mt-3">
              <img
                src={sampleImg2}
                alt="..."
                className="border rounded-lg h-40 w-40"
              />
              <button
                className={`w-56 bg-primary text-white py-2 my-3 mx-3 rounded-full focus:outline-none self-center`}
                onClick={(event) => {
                  history.push({
                    pathname: "viewimage",
                    state: { image: sampleImg2 },
                  });
                }}
              >
                View Sample Work Image 2
              </button>
            </div>
          </>
        )}
        {sampleImg3 && (
          <>
            <label htmlFor="sampleImg3" className="block text-sm">
              Sample Work Image 3:
            </label>
            <div className="flex justify-center mt-3">
              <img
                src={sampleImg3}
                alt="..."
                className="border rounded-lg h-40 w-40"
              />
              <button
                className={`w-56 bg-primary text-white py-2 my-3 mx-3 rounded-full focus:outline-none self-center`}
                onClick={(event) => {
                  history.push({
                    pathname: "viewimage",
                    state: { image: sampleImg3 },
                  });
                }}
              >
                View Sample Work Image 3
              </button>
            </div>
          </>
        )}
        <label htmlFor="location" className="block text-sm">
          City / Metro Area Served:
        </label>
        <input
          type="text"
          className="my-1 p-1 w-full border border-black-400"
          name="location"
          value={location}
          placeholder=""
          id="location"
          readOnly
        />
        <button
          className={`w-24 bg-primary text-white py-2 my-3 rounded-full focus:outline-none self-center`}
          onClick={(event) => {
            onPress(event);
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ViewSCPage;
