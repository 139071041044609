import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import { useHistory, useLocation } from "react-router-dom";

const stripe = loadStripe(
  // "pk_test_dwR67p5eRjZFfEMdXVLMNNOT00hBBoU2F0"
  "pk_live_oDqX9pjmVdTR2OdwzcXgeq4s003LsTxIZO"
);

const PayNowPage = () => {
  const history = useHistory();
  const {
    state: { client_secret, checkedEndContracts },
  } = useLocation();

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: client_secret,
    // Fully customizable with appearance API.
    appearance: {/*...*/},
  };

  const onClose = (e) => {
    e.preventDefault();
    history.push("/escrow");
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex justify-center items-center py-4">
        Escrow Process
      </div>

      {/* For ACH Payment */}

      <Elements stripe={stripe} options={options}>
        <CheckoutForm />
      </Elements>
      
      {/* For Debit-Card Payment */}
      {/* <Elements stripe={stripe}>
        <CheckoutForm 
          clientSecret={client_secret}
          checkedEndContracts={checkedEndContracts}
        />
      </Elements>

      <button
        className={`flex justify-center w-48 bg-primary text-white mx-auto py-2 mt-8 rounded-full focus:outline-none`}
        onClick={(event) => onClose(event)}
      >
        Close
      </button> */}
    </div>
  );
};

export default PayNowPage;
