import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../providers/Authentication";
import { database } from "../firebase";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const BudgetPage = () => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const {
    state: {
      neededService,
      location,
      zipCode,
      description,
      file1,
      file2,
      file3,
      file4,
      file5,
      file6,
    },
  } = useLocation();
  const [maxBid, setMaxBid] = useState("45000");
  const [checked, setChecked] = useState("first");
  const [phase1, setPhase1] = useState("");
  const [phase2, setPhase2] = useState("");
  const [phase3, setPhase3] = useState("");
  const [several, setSeveral] = useState(false);

  useEffect(() => {
    let restPayment = 0;
    if (phase1 && phase2) {
      restPayment = maxBid - phase1 - phase2;
      setPhase3("" + restPayment);
    } else {
      setPhase3("");
    }
    if (phase1 && phase2 && phase3 && restPayment > 0) {
      setSeveral(true);
    } else {
      setSeveral(false);
    }
  }, [maxBid, phase1, phase2, phase3]);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "maxBid") {
      setMaxBid(value);
    } else if (name === "phase1") {
      setPhase1(value);
    } else if (name === "phase2") {
      setPhase2(value);
    } else if (name === "phase3") {
      setPhase3(value);
    }
  };

  const onPress = async (e) => {
    e.preventDefault();
    const postKey = database.ref().child("JT").push().key;
    await database.ref(`JT/${postKey}`).set({
      UserUid: currentUser.UserUid,
      ServicesNeeded: neededService,
      Location: location,
      ZipCode: zipCode,
      Description: description,
      Upload: {
        file1,
        file2,
        file3,
        file4,
        file5,
        file6,
      },
      Payment: {
        Overall: maxBid,
        phase1,
        phase2,
        phase3,
      },
      JobId: postKey,
      Timestamp: (new Date()).toISOString(),
    });

    const msg = {
      personalizations: [
        {
          from: {
            email: "rey@subworknow.com",
            name: "SubWorkNow",
          },
          to: [
            {
              email: currentUser.Email,
              name: currentUser.LegalEntity,
            },
          ],
        },
      ],
      from: {
        email: "rey@subworknow.com",
        name: "SubWorkNow",
      },
      reply_to: {
        email: "rey@subworknow.com",
        name: "SubWorkNow",
      },
      subject: "Congratulations",
      content: [
        {
          type: "text/html",
          value: `<!doctype html>
            <html>
              <body>
                <div style="display: flex; align-items: center;">
                  <img src="https://i.ibb.co/b5WpDdv/logo.png" alt="logo" border="0">
                  <p style="font-weight: bold; margin-left: 8px; color: black;">SubWorkNow</p>
                </div>
                <p>Thank you for posting your JOB description on SubWorkNow.com.</p>
                <p>Should you have any questions please feel free to contact us at 1-844-SUBWORK1 (1-844-782-6691) or rey@subworknow.com.</p>
              </body>
            </html>
          `,
        },
      ],
    };

    axios
      .post(
        "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
        msg,
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function (response) {
      })
      .catch(function (error) {
      });
    history.push("/postsuccess");
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4 px-8">
        <div className="flex items-center">
          Maximum Offer:&nbsp;&nbsp;&nbsp;
          <div className="flex items-center text-primary font-bold text-2xl">
            $&nbsp;
            <input
              type="number"
              className="my-2 p-1 focus:outline-none font-bold border w-40 md:w-96"
              name="maxBid"
              value={maxBid}
              placeholder=""
              id="maxBid"
              onChange={onChangeHandler}
            />
          </div>
        </div>
        <div className="flex items-center mt-4">
          <input
            type="radio"
            name="first"
            value="first"
            checked={checked === "first"}
            onChange={() => setChecked("first")}
          />
          &nbsp;&nbsp;Overall Payment
        </div>
        <div className="flex items-center mt-4">
          <input
            type="radio"
            name="second"
            value="second"
            checked={checked === "second"}
            onChange={() => setChecked("second")}
          />
          &nbsp;&nbsp;Several Smaller Payments
        </div>
        <div className="flex items-center">
          Phase 1 Completion:&nbsp;&nbsp;&nbsp;
          <div className="flex items-center text-primary">
            $&nbsp;
            <input
              type="number"
              className="my-2 p-1 focus:outline-none border"
              name="phase1"
              value={phase1}
              placeholder=""
              id="phase1"
              onChange={onChangeHandler}
              disabled={checked === "second" ? false : true}
            />
          </div>
        </div>
        <div className="flex items-center">
          Phase 2 Completion:&nbsp;&nbsp;&nbsp;
          <div className="flex items-center text-primary">
            $&nbsp;
            <input
              type="number"
              className="my-2 p-1 focus:outline-none border"
              name="phase2"
              value={phase2}
              placeholder=""
              id="phase2"
              onChange={onChangeHandler}
              disabled={checked === "second" ? false : true}
            />
          </div>
        </div>
        <div className="flex items-center">
          Phase 3 Completion:&nbsp;&nbsp;&nbsp;
          <div className="flex items-center text-primary">
            $&nbsp;
            <input
              type="number"
              className="my-2 p-1 focus:outline-none border"
              name="phase3"
              value={phase3}
              placeholder=""
              id="phase3"
              onChange={onChangeHandler}
              disabled={checked === "second" ? false : true}
            />
          </div>
        </div>
        <button
          className={`w-48 ${
            !several && checked === "second" ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={(event) => {
            onPress(event);
          }}
          disabled={!several && checked === "second"}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default BudgetPage;
