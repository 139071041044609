import React, {useState, useEffect, useRef} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import { database } from "../firebase";

const PaymentStatus = ({checkedEndContracts}) => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [count, setCount] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setCount(prevCount => prevCount + 1);
    }, 10000);
  
    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );
    
    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({paymentIntent}) => {
        console.log("paymentIntent status=>", paymentIntent.status)
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Success! Payment received.');
            if(!!timerRef.current) clearTimeout(timerRef.current);
            checkedEndContracts.map(item => {
              const offerRef = database.ref(`OT/${item.OfferId}`);
              offerRef.update({
                funded: true,
                fundedTimestamp: (new Date()).toISOString(),
              });
            });
            break;

          case 'processing':
            setMessage("Payment processing. We'll update you when payment is received.");
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Payment failed. Please try another payment method.');
            if(!!timerRef.current) clearTimeout(timerRef.current);
            break;

          default:
            setMessage('Something went wrong.');
            break;
        }
      });
  }, [stripe, count]);


  return ( 
    <div className='text-center mt-8'>
      {message}
    </div>
  )
};

export default PaymentStatus;