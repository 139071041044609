import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import axios from "axios";
import { ThreeBounce } from "better-react-spinkit";
import { database } from "../firebase";
import { useHistory } from "react-router-dom";

const EscrowChargePage = () => {
  const { currentUser } = useContext(AuthContext);
  const [indicator, setIndicator] = useState(false);
  const [endedContracts, setEndedContracts] = useState([]);
  const [checkedEndContracts, setCheckedEndContracts] = useState([]);
  const [stripeCustomers, setStripeCustomers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    
    async function fetchData() {
      setIndicator(true);

      let stripeCustomers = [];
      const snapshot0 = await database.ref(`stripe_customers`).once("value");
      if (!!snapshot0.val()) {
        snapshot0.forEach((child) => {
          stripeCustomers.push(child.val());
        });
      }
      setStripeCustomers(stripeCustomers);
        
      let tempContracts = []; 
      let updatedContracts = [];

      await database.ref("OT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            if (
              child.val().isEnded &&
              child.val().UserUid === currentUser.UserUid
            ) 
              tempContracts.push(child.val());
          });
        }
      });
      let item = {};
      for (item of tempContracts) {
        let tempUserId = item.SCUid;
        const snapshot1 = await database.ref(`SCT/${tempUserId}`).once("value");
        const email = snapshot1.val().Email;
        const legal = snapshot1.val().LegalEntityName;
        let tempBidId = item.BidId;
        const snapshot2 = await database.ref(`BT/${tempBidId}`).once("value");
        const budget = snapshot2.val().Budget;
        const tempJobId = snapshot2.val().JobId;
        const snapshot3 = await database.ref(`JT/${tempJobId}`).once("value");
        const servicesNeeded = snapshot3.val().ServicesNeeded;
        const newItem = {
          ...item,
          email,
          legal,
          budget,
          servicesNeeded,
          checkVal: !!item.funded ? true : false,
        };
        updatedContracts.push(newItem);
      }
      setEndedContracts(updatedContracts);

      setIndicator(false);
    }

    fetchData();
  }, []);

  const handleCustomerCheck = (userUid) => {
    return stripeCustomers.some(item => userUid === item.userUid);
  }

  const onCheckChargeHandler = (position) => {
    const updatedEndedContracts = endedContracts.map((item, index) =>
      index === position ? {...item, checkVal: !item.checkVal} : {...item, checkVal: item.checkVal}
    );
    setEndedContracts(updatedEndedContracts);
    let temp = [];
    temp = updatedEndedContracts.filter(item => item.checkVal === true);
    setCheckedEndContracts(temp);
  }

  const onClickChargeHandler = async (event) => {
    event.preventDefault();
    
    const userInfo = currentUser;
    setIndicator(true);

    checkedEndContracts.map(item=> {
      const checkedOfferRef = database.ref(`OT/${item.OfferId}`);
      checkedOfferRef.update({
        checkVal: item.checkVal
      });
    })

    let tempAmount = 0;
    let item = {};
    for (item of checkedEndContracts) {
      tempAmount += item.budget * 100;
    }
    let totalAmount = Math.round(tempAmount);

    if(handleCustomerCheck(userInfo.UserUid) === true) {
      const snapshot2 = await database.ref(`stripe_customers/${userInfo.UserUid}`).once("value");
      const customerId = snapshot2.val().customerId;
      console.log("customerId=>", customerId);
      axios
        .post(
          "https://us-central1-subworknow.cloudfunctions.net/retriveStripeCustomer",
          {customerId: customerId},
          { headers: { "Content-Type": "application/json" } }
        )
        .then(function (response) {
          setIndicator(false);
          const data = response.data;
          const status = response.status;
          if(status === 200) {
            onCardConnect(data.id, totalAmount);
          } else {
          }
        })
        .catch(function (error) {
          console.log("retrive customer error=>",error)
          setIndicator(false);
        });
    } else {
      axios
        .post(
          "https://us-central1-subworknow.cloudfunctions.net/createNewStripeCustomer",
          userInfo,
          { headers: { "Content-Type": "application/json" } }
        )
        .then(function (response) {
          setIndicator(false);
          const data = response.data;
          const status = response.status;
          if(status === 200) {            
            onCardConnect(data.id, totalAmount);
          } else {
          }
        })
        .catch(function (error) {
          console.log("create customer error=>",error);
          setIndicator(false);
        });
    }
  }

  const onCardConnect = (customerId, totalAmount) => {
    setIndicator(true);
    axios
      .post(
        "https://us-central1-subworknow.cloudfunctions.net/createPaymentIntentACH",
        // "http://localhost:5000/subworknow/us-central1/createPaymentIntentACH",
        {
          amount: totalAmount,
          currency: 'usd',
          customerId: customerId
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function (response) {
        const data = response.data;
        const status = response.status;
        if(status === 200) {
          const client_secret = data.client_secret;
          const stripeCustomersRef = database.ref(`stripe_customers/${currentUser.UserUid}/payment`);
          stripeCustomersRef.update({
            paymentIntentId: data.id,
            totalAmount: totalAmount
          });
          setIndicator(false);
          history.push({
            pathname: "paynow",
            state: { client_secret, checkedEndContracts },
          });
        } else {
          setIndicator(false);
        }
      })
      .catch(function (error) {
        setIndicator(false);
        console.log("payment intent error=>",error)
      });
  }

  return (
    <div className="w-full">
      <Navbar />
      {indicator &&
        <div className="flex justify-center items-center flex-col my-4">
          <ThreeBounce size={24} color="#03a9f4" />
          Please wait...
        </div>
      }
      <div className="flex justify-center items-center py-4">
        Escrow Process
      </div>
      { endedContracts.length === 0 ? 
        <p className="flex justify-center mx-auto">No ended contracts yet.</p>
        : <div>
          {endedContracts.map((contract, i) => 
            <div key={i} className="flex justify-between w-full max-w-xl items-center mx-auto border rounded p-4 my-4">
              <div>
                <div>Job: {contract.servicesNeeded}</div>
                <div>SC LegalEntity: {contract.legal}</div>
                <div>Budget: ${contract.budget}</div>
                <div>Funded: {!!contract.funded ? "Yes" : "No"}</div>
                <div>Ended Date: {contract.Timestamp}</div>
              </div>
              <label className="mt-2">
                <input
                  type="checkbox"
                  name={`release-${i}`}
                  checked={contract.checkVal}
                  onChange={() => onCheckChargeHandler(i)}
                  disabled={!!contract.funded ? true : false}
                />
              </label>
            </div>
          )}
          <button
            className="bg-gray-500 flex justify-center mx-auto w-full max-w-xl mt-6 py-2 text-white rounded"
            disabled={true}
          >
            Fund/Pay by Credit-Debit Card payment (Coming Soon!)
          </button>
          <button
            className={`${
              checkedEndContracts.length > 0 ? "bg-primary" : "bg-gray-500"
            } flex justify-center mx-auto w-full max-w-xl my-6 py-2 text-white rounded`}
            onClick={(event) => {
              onClickChargeHandler(event);
            }}
            disabled={checkedEndContracts.length === 0}
          >
            Fund/Pay by ACH payment
          </button>
        </div> 
      }
    </div>
  );
};

export default EscrowChargePage;