import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { analytics, database } from "../firebase";
import avatar from "../assets/image/avatar.png";
import escrowSymbol from "../assets/image/escrow.jpg";
import gcAndSc from "../assets/image/gc_and_sc.jpg";
import jobCompleted from "../assets/image/job_completed.jpg";
import postPayment from "../assets/image/post_payment.jpg";
import rightArrow from "../assets/image/right_arrow.png";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import { ThreeBounce } from "better-react-spinkit";
import Modal from "react-modal";
import JobDetailPage from "./JobDetailPage";
import Tooltip from "../components/Tooltip";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const DATA = [
  "All",
  "Cabinet & Shelf Installation",
  "Cleaning Prep",
  "Door & Garage Installation",
  "Drywall & Plastering",
  "Electrical",
  "Excavation & Leveling",
  "Fencing",
  "Flooring",
  "Foundation, Cement & Concrete",
  "Framing",
  "Glass & Mirror",
  "HVAC",
  "Insulation",
  "Kitchen Appliance Installation",
  "Kitchen & Bathroom Counter Installation",
  "Lawn & Garden",
  "Masonry",
  "Painting",
  "Plumbing",
  "Post construction Cleaning",
  "Roofing",
  "Siding & Stucco",
  "Solar Installation",
];

const DATA1 = [
  "All",
  "Austin, TX Metro Area",
  "Dallas, TX Metro Area",
  "Fort Worth, TX Metro Area",
  "Houston, TX Metro Area",
  "Oklahoma City, OK Metro Area",
  "Portland, OR Metro Area",
  "San Antonio, TX Metro Area",
  "Seattle, WA Metro Area",
];

const MyJobsPage = () => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [selectedSub, setSelectedSub] = useState(DATA[0]);
  const [selectLocation, setSelectLocation] = useState(DATA1[0]);
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState([]);
  const [renderedJobs, setRenderedJobs] = useState([]);
  const [indicator, setIndicator] = useState(false);
  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isViewJobDetail, setIsViewJobDetail] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (currentUser && currentUser.userType === 1) {
        analytics.logEvent("sc_jobs_page");
        setIndicator(true);
        setSelectedSub(DATA[0]);
        setSelectLocation(DATA1[0]);
        const sEmail = currentUser.Email;
        const sLegal = currentUser.LegalEntityName;
        let tempJobs = [];
        let updatedJobs = [];
        await database.ref("JT").once("value", (snapshot) => {
          if (!!snapshot.val()) {
            snapshot.forEach((child) => {
              if (!child.val().isHidden) tempJobs.push(child.val());
            });
          }
        });
        let item = {};
        for (item of tempJobs) {
          let bidAvailability = false;
          let tempUserId = item.UserUid;
          let snapshot1 = null;
          const snap = await database.ref(`GCT/${tempUserId}`).once("value");
          if (!!snap.val()) {
            snapshot1 = snap;
          } else {
            snapshot1 = await database.ref(`RT/${tempUserId}`).once("value");
          }
          const icon = snapshot1.val() ? snapshot1.val().IconUpload : ""; // a user's avatar that post this job
          const gEmail = snapshot1.val() ? snapshot1.val().Email : ""; // a user's email that post this job
          const gLegal = snapshot1.val() ? snapshot1.val().LegalEntity : "";
          const gFcmToken = snapshot1.val() ? snapshot1.val().fcmToken : "";
          const gPlayerId = snapshot1.val() ? snapshot1.val().PlayerId : "";
          let tempJobId = item.JobId;
          await database
            .ref("BT")
            .once("value", (snapshot3) => {
              if (!!snapshot3.val()) {
                snapshot3.forEach((child) => {
                  if (
                    tempJobId === child.val().JobId &&
                    currentUser.UserUid === child.val().UserUid
                  ) {
                    bidAvailability = true;
                  }
                });
              }
            })
            .then(() => {
              const newItem = {
                ...item,
                icon,
                gEmail,
                sEmail,
                gLegal,
                sLegal,
                gFcmToken,
                bidAvailability,
                gPlayerId,
              };
              updatedJobs.push(newItem);
            });
        }
        setJobs(updatedJobs);
        setTotalJobs(updatedJobs);
        setPageCount(Math.ceil(updatedJobs.length / 5));
        setIndicator(false);
      } else if (
        currentUser &&
        (currentUser.userType === 0 || currentUser.userType === 2)
      ) {
        if (currentUser.userType === 0) {
          analytics.logEvent("gc_jobs_page");
        } else if (currentUser.userType === 2) {
          analytics.logEvent("r_jobs_page");
        }
        setIndicator(true);
        setSelectedSub(DATA[0]);
        setSelectLocation(DATA1[0]);
        let tempJobs = [];
        let updatedJobs = [];

        await database.ref("JT").once("value", (snapshot) => {
          if (!!snapshot.val()) {
            snapshot.forEach((child) => {
              if (
                child.val().UserUid === currentUser.UserUid &&
                !child.val().isHidden
              ) {
                tempJobs.push(child.val());
              }
            });
          }
        });

        let item = {};
        for (item of tempJobs) {
          let checkBids = false;
          let tempJobId = item.JobId;
          await database
            .ref("BT")
            .once("value", (snapshot3) => {
              if (!!snapshot3.val()) {
                snapshot3.forEach((child) => {
                  if (tempJobId === child.val().JobId) {
                    checkBids = true;
                  }
                });
              }
            })
            .then(() => {
              const newItem = { ...item, checkBids };
              updatedJobs.push(newItem);
            });
        }

        setJobs(updatedJobs);
        setTotalJobs(updatedJobs);
        setPageCount(Math.ceil(updatedJobs.length / 5));
        setIndicator(false);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setRenderedJobs(
      jobs.filter(
        (job, index) => index >= (offset - 1) * 5 && index < offset * 5
      )
    );
  }, [jobs, offset]);

  const onCloseView = () => {
    setIsViewJobDetail(false);
  };

  const onPressView = (e, job) => {
    e.preventDefault();
    setSelectedJob(job);
    setIsViewJobDetail(true);
  };

  const onPressBid = (e, job) => {
    e.preventDefault();
    history.push({
      pathname: "bid",
      state: { job },
    });
  };

  const filterJobs = (item) => {
    setSelectedSub(item);
    setOffset(1);
    if (item === "All") {
      const tempJobs = totalJobs.filter(
        (job) => selectLocation === "All" || job.Location === selectLocation
      );
      setJobs(tempJobs);
      setPageCount(Math.ceil(tempJobs.length / 5));
    } else {
      const tempJobs = totalJobs.filter(
        (job) =>
          job.ServicesNeeded === item &&
          (selectLocation === "All" || job.Location === selectLocation)
      );
      setJobs(tempJobs);
      setPageCount(Math.ceil(tempJobs.length / 5));
    }
  };

  const filterJobs1 = (item) => {
    setSelectLocation(item);
    setOffset(1);
    if (item === "All") {
      const tempJobs = totalJobs.filter(
        (job) => selectedSub === "All" || job.ServicesNeeded === selectedSub
      );
      setJobs(tempJobs);
      setPageCount(Math.ceil(tempJobs.length / 5));
    } else {
      const tempJobs = totalJobs.filter(
        (job) =>
          (selectedSub === "All" || job.ServicesNeeded === selectedSub) &&
          job.Location === item
      );
      setJobs(tempJobs);
      setPageCount(Math.ceil(tempJobs.length / 5));
    }
  };

  const onPressModify = (e, job) => {
    e.preventDefault();
    analytics.logEvent("modify_job_button");
    history.push({
      pathname: "modifyjob",
      state: { job },
    });
  };

  const deleteJob = (e, job) => {
    e.preventDefault();
    analytics.logEvent("delete_job_button");
    setSelectedJob(job);
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const onPressDelete = () => {
    try {
      setIndicator(true);
      const key = selectedJob.JobId;
      database
        .ref(`JT/${key}`)
        .remove()
        .then(async (res) => {
          setIndicator(false);
          toast("Delete Success!");
          let bids = [];
          const snapshot = await database.ref(`BT`).once("value");
          if (!!snapshot.val()) {
            snapshot.forEach((child) => {
              bids.push(child.val());
            });
          }

          let offers = [];
          const snapshot1 = await database.ref(`OT`).once("value");
          if (!!snapshot1.val()) {
            snapshot1.forEach((child) => {
              offers.push(child.val());
            });
          }

          const filterBids = bids.filter((bid) => bid.JobId === key);
          if (filterBids && filterBids.length > 0) {
            filterBids.forEach((bid) => {
              database.ref(`BT/${bid.BidId}`).remove();
            });
          }

          const filterOffers = offers.filter((offer) => offer.JobId === key);
          if (filterOffers && filterOffers.length > 0) {
            filterOffers.forEach((offer) => {
              database.ref(`OT/${offer.OfferId}`).remove();
            });
          }

          setTimeout(() => history.go(0), 2000);
        })
        .catch((err) => {
          setIndicator(false);
          toast("Delete Failed!");
        });
    } catch (error) {
      toast("Delete Failed");
    }
    setIsOpen(false);
  };

  const onPressBids = (e, job) => {
    e.preventDefault();
    analytics.logEvent("current_bids_button");
    history.push({
      pathname: "currentbids",
      state: { job },
    });
  };

  const handlePageClick = (data) => {
    setOffset(data.selected + 1);
  };

  if (currentUser.userType === 0 || currentUser.userType === 2) {
    return (
      <div className="w-full">
        <Navbar />
        {indicator ? (
          <div className="flex justify-center items-center flex-col my-80">
            <ThreeBounce size={24} color="#03a9f4" />
            Please wait...
          </div>
        ) : (
          <div className="flex flex-col items-center py-4">
            <div className="flex flex-col md:flex-row">
              <Dropdown
                options={DATA}
                onChange={(value) => filterJobs(value.value)}
                value={DATA[0]}
                placeholder=""
                className="mt-2 mb-4 w-72 md:mr-4"
              />
              <Dropdown
                options={DATA1}
                onChange={(value) => filterJobs1(value.value)}
                value={DATA1[0]}
                placeholder=""
                className="mt-2 mb-4 w-72 md:ml-4"
              />
            </div>
            {renderedJobs.length === 0 ? (
              <p>Any Posted Jobs will Appear Momentarily.</p>
            ) : (
              renderedJobs.map((job) => (
                <div className="w-96 border px-4 py-2 my-2" key={job.JobId}>
                  <div className="flex items-center">
                    <img
                      src={
                        currentUser.IconUpload ? currentUser.IconUpload : avatar
                      }
                      alt="..."
                      className="w-16 h-16 rounded-full"
                    />
                    <div className="ml-4 flex-1">
                      <p>{job.ServicesNeeded}</p>
                      <p>Max Bid: ${job.Payment.Overall}</p>
                      <p>{job.Description}</p>
                      <p>
                        Location: {job.Location} {job.ZipCode}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-evenly">
                    <button
                      className={`w-24 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
                      onClick={(event) => onPressModify(event, job)}
                    >
                      Modify
                    </button>
                    <button
                      className={`w-24 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
                      onClick={(event) => deleteJob(event, job)}
                    >
                      Delete
                    </button>
                    <button
                      className={`w-24 ${
                        job.checkBids ? "bg-green-500" : "bg-primary"
                      } text-white py-2 my-3 rounded-full focus:outline-none`}
                      onClick={(event) => onPressBids(event, job)}
                    >
                      Bids
                    </button>
                  </div>
                </div>
              ))
            )}
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"flex justify-center my-4 items-center"}
              activeClassName={"primary font-bold font-lg"}
              pageClassName={"px-2 text-xl border"}
              previousClassName={"border px-3 primary mr-2"}
              nextClassName={"border px-3 primary ml-2"}
            />
          </div>
        )}
        <ToastContainer />
        <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
          <p className="text-lg">Do you really want to delete this job?</p>
          <div className="flex justify-center">
            <button
              className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
              onClick={onPressDelete}
            >
              OK
            </button>
            <button
              className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
              onClick={onCloseModal}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  return (
    <div className="w-full">
      <Navbar />
      {indicator ? (
        <div className="flex justify-center items-center flex-col my-80">
          <ThreeBounce size={24} color="#03a9f4" />
          Please wait...
        </div>
      ) : !isViewJobDetail || !selectedJob ? (
        <div className="flex flex-col items-center py-4">
          <div className="flex justify-center items-center text-center text-sm my-8 flex-wrap">
            <div className="max-w-160">
              GC Posts "Job"
              <img
                src={jobCompleted}
                alt="..."
                className="w-16 h-auto m-auto"
              />
            </div>
            <img src={rightArrow} alt="..." className="w-16 h-8" />
            <div className="max-w-160">
              SC Places "Bid/Proposal" on Posted "Job"
              <img
                src={jobCompleted}
                alt="..."
                className="w-16 h-auto m-auto"
              />
            </div>
            <img src={rightArrow} alt="..." className="w-16 h-8" />
            <div className="max-w-160">
              GC Selects a "Bid/Proposal"
              <img src={gcAndSc} alt="..." className="w-16 h-auto m-auto" />
            </div>
            <img src={rightArrow} alt="..." className="w-16 h-8" />
            <div className="max-w-160">
              SC Receives an "Offer" from GC
              <img src={gcAndSc} alt="..." className="w-16 h-auto m-auto" />
            </div>
            <img src={rightArrow} alt="..." className="w-16 h-8" />
            <div className="max-w-160">
              SC Accepts "Offer" Thus Making a "Contract"
              <img src={gcAndSc} alt="..." className="w-16 h-auto m-auto" />
            </div>
            <img src={rightArrow} alt="..." className="w-16 h-8" />
            <div className="max-w-160">
              SC Completes "Job" and "Notifies" GC
              <img src={postPayment} alt="..." className="w-16 h-auto m-auto" />
            </div>
            <img src={rightArrow} alt="..." className="w-16 h-8" />
            <div className="max-w-160">
              GC & SC Payment via "Escrow" (Coming Soon!)
              <img
                src={escrowSymbol}
                alt="..."
                className="w-16 h-auto m-auto"
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <Dropdown
              options={DATA}
              onChange={(value) => filterJobs(value.value)}
              value={selectedSub}
              placeholder=""
              className="mt-2 mb-4 w-72 md:mr-4"
            />
            <Dropdown
              options={DATA1}
              onChange={(value) => filterJobs1(value.value)}
              value={selectLocation}
              placeholder=""
              className="mt-2 mb-4 w-72 md:ml-4"
            />
          </div>
          {renderedJobs.length === 0 ? (
            <p>Any Posted Jobs will Appear Momentarily.</p>
          ) : (
            renderedJobs.map((job) => (
              <div
                className="relative w-96 border px-4 py-2 my-2"
                key={job.JobId}
              >
                <div className="absolute right-2">
                  <Tooltip text="Coming Soon - This Icon Will Appear When a Job is Funded.">
                    <img src={escrowSymbol} alt="..." className="w-12 h-12" />
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  <img
                    src={job.icon ? job.icon : avatar}
                    alt="..."
                    className="w-16 h-16 rounded-full"
                  />
                  <div className="ml-4 flex-1">
                    <p>{job.ServicesNeeded}</p>
                    <p>Max Bid: ${job.Payment.Overall}</p>
                    <p>{job.Description}</p>
                    <p>
                      Location: {job.Location} {job.ZipCode}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-evenly">
                  <button
                    className={`w-24 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
                    onClick={(event) => onPressView(event, job)}
                  >
                    View
                  </button>
                  <button
                    className={`w-24 ${
                      job.bidAvailability ? "bg-gray-500" : "bg-primary"
                    } text-white py-2 my-3 rounded-full focus:outline-none`}
                    onClick={(event) => onPressBid(event, job)}
                    disabled={job.bidAvailability}
                  >
                    Place Bid
                  </button>
                </div>
              </div>
            ))
          )}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"flex justify-center my-4 items-center"}
            activeClassName={"primary font-bold font-lg"}
            pageClassName={"px-2 text-xl border"}
            previousClassName={"border px-3 primary mr-2"}
            nextClassName={"border px-3 primary ml-2"}
            initialPage={offset - 1}
          />
        </div>
      ) : (
        <JobDetailPage job={selectedJob} onCloseView={onCloseView} />
      )}
      <ToastContainer />
    </div>
  );
};

export default MyJobsPage;
