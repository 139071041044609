import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import { analytics, database, firestore } from "../firebase";
import { useHistory } from "react-router-dom";
import { ThreeBounce } from "better-react-spinkit";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const MessagingPage = () => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [threads, setThreads] = useState([]);
  const [indicator, setIndicator] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedThread, setSelectedThread] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setIndicator(true);

      let gcUsers = [];
      const snapshot = await database.ref(`GCT`).once("value");
      if (!!snapshot.val()) {
        snapshot.forEach((child) => {
          gcUsers.push(child.val());
        });
      }
      const snapshot1 = await database.ref(`RT`).once("value");
      if (!!snapshot1.val()) {
        snapshot1.forEach((child) => {
          gcUsers.push(child.val());
        });
      }

      let selectedThreads = [];
      let item = {};
      for (item of gcUsers) {
        let tempUserId = item.UserUid;
        if (!!tempUserId) {
          const querySnapshot = await firestore.collection(tempUserId).get();
          if (!!querySnapshot) {
            for (const doc of querySnapshot.docs) {
              let temp = doc.data();
              if (
                temp.name &&
                temp.name.split(":")[2] &&
                temp.name.split(":")[2].trim() === currentUser.UserUid
              ) {
                let res = {
                  _id: doc.id,
                  // give defaults
                  name: "",
                  latestMessage: {
                    text: "",
                  },
                  userUid: tempUserId,
                  ...temp,
                };
                selectedThreads.push(res);
              }
            }
          }
        }
      }
      setThreads(selectedThreads);
      setIndicator(false);
    }

    analytics.logEvent('messaging_page');
    if (currentUser.userType === 0 || currentUser.userType === 2) {
      const unsubscribe = firestore
        .collection(currentUser.UserUid)
        .orderBy("latestMessage.createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          const threads = querySnapshot.docs.map((documentSnapshot) => {
            return {
              _id: documentSnapshot.id,
              // give defaults
              name: "",

              latestMessage: {
                text: "",
              },
              ...documentSnapshot.data(),
            };
          });
          setThreads(threads);

          if (indicator) {
            setIndicator(false);
          }
        });

      /**
       * unsubscribe listener
       */
      return () => unsubscribe();
    } else if (currentUser.userType === 1) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  const deleteRoom = (item) => {
    setSelectedThread(item);
    setIsOpen(true);
  };

  const onDeleteRoom = () => {
    firestore.collection(currentUser.UserUid).doc(selectedThread._id).delete();
    setIsOpen(false);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const onJoin = (e, thread) => {
    e.preventDefault();
    history.push({
      pathname: "room",
      state: { thread },
    });
  };

  const addRoom = (e) => {
    e.preventDefault();
    history.push("addroom");
  };

  return (
    <div className="w-full">
      <Navbar />
      {indicator ? (
        <div className="flex justify-center items-center flex-col my-80">
          <ThreeBounce size={24} color="#03a9f4" />
          Please wait...
        </div>
      ) : (
        <div className="flex flex-col items-center py-4">
          {threads.length === 0 ? (
            <p>No chat rooms yet.</p>
          ) : (
            threads.map((thread) => (
              <div className="w-80 md:w-96 border px-4 py-2 my-2" key={thread._id}>
                <p>{thread.name.split(":")[0].trim()}</p>
                <p>{thread.name.split(":")[1].trim()}</p>
                <p>{thread.name.split(":")[3].trim()}</p>
                <p>{thread.name.split(":")[4].trim()}</p>
                <p className="truncate">{thread.latestMessage.text}</p>
                <div className="flex items-center justify-evenly">
                  <button
                    className={`w-24 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
                    onClick={(event) => onJoin(event, thread)}
                  >
                    Join
                  </button>
                  {currentUser && (currentUser.userType === 0 || currentUser.userType === 2) && (
                    <button
                      className={`w-24 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
                      onClick={(event) => deleteRoom(thread)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            ))
          )}
          {currentUser && (currentUser.userType === 0 || currentUser.userType === 2) && (
            <button
              className={`w-32 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
              onClick={addRoom}
            >
              Add Room
            </button>
          )}
        </div>
      )}
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">Do you really want to delete this room?</p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onDeleteRoom}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default MessagingPage;
