import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import { firestore, database, analytics } from "../firebase";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddGroupRoomPage = () => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [indicator, setIndicator] = useState(false);

  const [groupName, setGroupName] = useState("");

  const [checkedState, setCheckedState] = useState(false);

  /**
   * Create a new Firestore collection to save threads
   */
  function handleButtonPress() {
    setIndicator(true);
    if (currentUser.userType === 0) {
      firestore
        .collection(`Group-${currentUser.UserUid}`)
        .add({
          name: `${currentUser.UserUid}:${groupName}`,
          latestMessage: {
            text: `You have joined the group chat room - ${groupName}.`,
            createdAt: new Date().getTime(),
          },
        })
        .then((docRef) => {
          docRef.collection("MESSAGES").add({
            text: `You have joined the group chat room - ${groupName}.`,
            createdAt: new Date().getTime(),
            system: true,
          });
        });
    } else if (currentUser.userType === 2) {
      firestore
        .collection(`GroupR-${currentUser.UserUid}`)
        .add({
          name: `${currentUser.UserUid}:${groupName}`,
          latestMessage: {
            text: `You have joined the group chat room - ${groupName}.`,
            createdAt: new Date().getTime(),
          },
        })
        .then((docRef) => {
          docRef.collection("MESSAGES").add({
            text: `You have joined the group chat room - ${groupName}.`,
            createdAt: new Date().getTime(),
            system: true,
          });
        });
    }
    history.goBack();   
  }

  const onChangeGNHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "groupName") {
      setGroupName(value);
    }
  }

  const onChangeCheckHandler = (e) => {
    setCheckedState(e.currentTarget.checked);
  }

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        <p>New Group Chat</p>
        <input
          type="text"
          className="mt-2 mb-4 p-2 w-80 md:w-96 border border-black-400"
          name="groupName"
          value={groupName}
          placeholder="Group name"
          id="groupName"
          onChange={(event) => onChangeGNHandler(event)}
        />
        <p className="mt-10">Create New Group</p>
        <div className="mt-2 mb-4 p-2 w-80 md:w-96 border border-black-400">
          <label className="flex justify-between items-center py-2">
            <span className="text-dark font-light ml-2 w-full text-sm">
              {currentUser.userType === 0 ? "Add All SubWorkNow.com General Contractors" : "Add All SubWorkNow.com Realtors"}
            </span>
            <input 
              type="checkbox" 
              id="add-all"
              className="form-checkbox" 
              onChange={(e) => onChangeCheckHandler(e)}
            />
          </label>          
        </div>
        <button
          className={`w-32 ${
            (groupName !== "" && checkedState === true) ? "bg-primary" : "bg-gray-500"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={() => handleButtonPress()}
          disabled={groupName === "" || checkedState === false}
        >
          Create
        </button>
        <button
          className={`w-32 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={() => history.goBack()}
        >
          Close
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddGroupRoomPage;
