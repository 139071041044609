import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import avatar from "../assets/image/avatar.png";
import { AuthContext } from "../providers/Authentication";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { database } from "../firebase";
import axios from "axios";

const BidPage = () => {
  const {
    state: { job },
  } = useLocation();
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [budget, setBudget] = useState("");
  const [proposal, setProposal] = useState("");
  const [timeline, setTimeline] = useState("");
  // eslint-disable-next-line
  const [indicator, setIndicator] = useState(false);
  const [gEmail, setGEmail] = useState("");
  const [sEmail, setSEmail] = useState("");
  const [gLegal, setGLegal] = useState("");
  const [sLegal, setSLegal] = useState("");
  const [gPlayerId, setGPlayerId] = useState("");
  const [longTitle, setLongTitle] = useState("General Contractor");

  useEffect(() => {
    if (currentUser.userType === 0) {
      setLongTitle("General Contractor");
    } else if (currentUser.userType === 2) {
      setLongTitle("Realtor");
    }
    setGEmail(job.gEmail);
    setSEmail(job.sEmail);
    setGLegal(job.gLegal);
    setSLegal(job.sLegal);
    setGPlayerId(job.gPlayerId);
    // eslint-disable-next-line
  }, []);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "budget") {
      setBudget(value);
    } else if (name === "proposal") {
      setProposal(value);
    } else if (name === "timeline") {
      setTimeline(value);
    }
  };

  const onPlaceBid = async (e) => {
    e.preventDefault();
    try {
      setIndicator(true);
      const postKey = database.ref().child("BT").push().key;
      database
        .ref(`BT/${postKey}`)
        .set({
          JobId: job.JobId,
          UserUid: currentUser.UserUid,
          Budget: budget,
          Proposal: proposal,
          Timeline: timeline,
          BidId: postKey,
        })
        .then((res) => {
          const msg = {
            personalizations: [
              {
                from: {
                  email: "rey@subworknow.com",
                  name: "SubWorkNow",
                },
                to: [
                  {
                    email: gEmail,
                    name: gLegal,
                  },
                ],
              },
            ],
            from: {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
            reply_to: {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
            subject: `${longTitle}: You have a new message`,
            content: [
              {
                type: "text/html",
                value: `<!doctype html>
                <html>
                  <body>
                    <div style="display: flex; align-items: center;">
                      <img src="https://i.ibb.co/b5WpDdv/logo.png" alt="logo" border="0">
                      <p style="font-weight: bold; margin-left: 8px; color: black;">SubWorkNow</p>
                    </div>
                    <p>Hi, ${gLegal}. ${sLegal} has just submitted a bid on your posted job.</p>
                    <p>Budget: ${budget}, Proposal: "${proposal}", Timeline: ${timeline}</p>
                  </body>
                </html>
              `,
              },
            ],
          };

          axios
            .post(
              "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
              msg,
              { headers: { "Content-Type": "application/json" } }
            )
            .then(function (response) {
            })
            .catch(function (error) {
            });

          const msg1 = {
            personalizations: [
              {
                from: {
                  email: "rey@subworknow.com",
                  name: "SubWorkNow",
                },
                to: [
                  {
                    email: sEmail,
                    name: sLegal,
                  },
                ],
              },
            ],
            from: {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
            reply_to: {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
            subject: "Congratulations",
            content: [
              {
                type: "text/html",
                value: `<!doctype html>
                <html>
                  <body>
                    <div style="display: flex; align-items: center;">
                      <img src="https://i.ibb.co/b5WpDdv/logo.png" alt="logo" border="0">
                      <p style="font-weight: bold; margin-left: 8px; color: black;">SubWorkNow</p>
                    </div>
                    <p>Thank you for bidding.</p>
                    <p>Any accepted bid or proposal will be forwarded to you via app notification and/or email. Should you have any questions please feel free to contact us at 1-844-SUBWORK1 (1-844-782-6691) or rey@subworknow.com.</p>
                  </body>
                </html>
              `,
              },
            ],
          };

          axios
            .post(
              "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
              msg1,
              { headers: { "Content-Type": "application/json" } }
            )
            .then(function (response) {
            })
            .catch(function (error) {
            });

          const pushContent = {
            app_id: "f6696b79-ba9e-4042-a68e-d3c38bb2a741", // Live
            // app_id: 'e048cef2-fc4d-4cfc-aea0-c3ad1b5ee3eb', // Localhost
            include_player_ids: [gPlayerId],
            contents: {
              en: `Hi, ${gLegal}. ${sLegal} has just submitted a bid on your posted job.`,
            },
          };
          axios
            .post("https://onesignal.com/api/v1/notifications", pushContent)
            .then(function (response) {
            })
            .catch(function (error) {
            });
          history.push("/congratulation");
        })
        .catch((err) => {
          setIndicator(false);
          toast("Bid Failed!");
        });
    } catch (error) {
      toast("Bid Failed!");
    }
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center p-4">
        <div className="flex items-center mb-4">
          <img
            src={job.icon ? job.icon : avatar}
            alt="..."
            className="w-16 h-16 rounded-full"
          />
          <div className="ml-4">
            <p>{job.ServicesNeeded}</p>
            <p>Max Bid: ${job.Payment.Overall}</p>
            <p>{job.Description}</p>
            <p>
              Location: {job.Location} {job.ZipCode}
            </p>
          </div>
        </div>
        Your Budget:
        <div className="mt-2 mb-4 flex items-center relative">
          <span className="absolute left-2">$</span>
          <input
            type="number"
            className="p-2 pl-4 w-80 md:w-96 border border-black-400"
            name="budget"
            value={budget}
            placeholder=""
            id="budget"
            min={0}
            onChange={(event) => onChangeHandler(event)}
          />
        </div>
        Your Proposal:
        <textarea
          className="mt-2 p-2 w-80 md:w-96 h-36 border border-black-400 mb-4"
          name="proposal"
          value={proposal}
          placeholder=""
          id="proposal"
          onChange={(event) => onChangeHandler(event)}
        />
        Your Timeline:
        <input
          className="mt-2 mb-4 p-2 w-80 md:w-96 border border-black-400"
          name="timeline"
          value={timeline}
          placeholder=""
          id="timeline"
          onChange={(event) => onChangeHandler(event)}
        />
        <button
          className={`w-48 ${
            budget && timeline && proposal ? "bg-primary" : "bg-gray-500"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={(event) => {
            onPlaceBid(event);
          }}
          disabled={!budget || !timeline || !proposal}
        >
          Next
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BidPage;
