import React from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";

const ViewImagePage = () => {
  const history = useHistory();
  const {
    state: { image },
  } = useLocation();

  return (
    <div className="w-full text-center">
      <Navbar />
      <img src={image} alt="..." className="h-96 w-auto m-auto border" />
      <button
        className={`w-48 bg-primary text-white py-2 mt-8 rounded-full focus:outline-none mb-3`}
        onClick={(event) => history.goBack()}
      >
        Close
      </button>
    </div>
  );
};

export default ViewImagePage;
