import React, { useEffect, useContext, useState } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../providers/Authentication";
import { database } from "../firebase";
import axios from "axios";
import avatar from "../assets/image/avatar.png";

const ViewOfferPage = () => {
  const {
    state: { offer },
  } = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  // eslint-disable-next-line
  const [indicator, setIndicator] = useState(false);
  const [description, setDescription] = useState("");
  const [sendUserIcon, setSendUserIcon] = useState("");
  const [budget, setBudget] = useState("");
  const [proposal, setProposal] = useState("");
  const [timeline, setTimeline] = useState("");
  const [job, setJob] = useState({});
  const [overall, setOverall] = useState("");
  const [gEmail, setGEmail] = useState("");
  const [sEmail, setSEmail] = useState("");
  const [gLegal, setGLegal] = useState("");
  const [sLegal, setSLegal] = useState("");
  const [gPlayerId, setGPlayerId] = useState("");
  const [offerData, setOfferData] = useState(offer);

  const readOfferData = async () => {
    try {
      setIndicator(true);
      let description = offerData ? offerData.Description : "";
      let bidId = offerData ? offerData.BidId : "";
      // eslint-disable-next-line
      let sendUserUid = offerData ? offerData.UserUid : "";
      let sendUserIcon = offerData ? offerData.gIcon : "";
      let gEmail = offerData ? offerData.gEmail : "";
      let gLegal = offerData ? offerData.gLegal : "";
      let gPlayerId = offerData ? offerData.gPlayerId : "";
      const snapshot2 = await database.ref(`BT/${bidId}`).once("value");
      let budget = snapshot2.val().Budget;
      let proposal = snapshot2.val().Proposal;
      let timeline = snapshot2.val().Timeline;
      let jobId = snapshot2.val().JobId;
      const snapshot3 = await database.ref(`JT/${jobId}`).once("value");
      let job = snapshot3.val();
      let overall = job.Payment.Overall;
      const snapshot4 = await database
        .ref(`SCT/${currentUser.UserUid}`)
        .once("value");
      let sEmail = snapshot4.val().Email;
      let sLegal = snapshot4.val().LegalEntityName;
      setDescription(description);
      setSendUserIcon(sendUserIcon);
      setGEmail(gEmail);
      setGLegal(gLegal);
      setGPlayerId(gPlayerId);
      setSEmail(sEmail);
      setSLegal(sLegal);
      setBudget(budget);
      setProposal(proposal);
      setTimeline(timeline);
      setJob(job);
      setOverall(overall);
      setIndicator(false);
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {
    readOfferData();
    // eslint-disable-next-line
  }, [offerData]);

  const onAcceptPress = (e) => {
    e.preventDefault();
    onSendEmail(true);
  };

  const onDeclinePress = (e) => {
    e.preventDefault();
    onSendEmail(false);
  };

  const onSendEmail = async (isAccepted) => {
    const gcUserRef = database.ref(`OT/${offerData.OfferId}`);
    await gcUserRef.update({
      isAccepted,
      isReplied: true,
    });
    try {
      setIndicator(true);

      const pushContent = {
        app_id: "f6696b79-ba9e-4042-a68e-d3c38bb2a741", // Live
        // app_id: 'e048cef2-fc4d-4cfc-aea0-c3ad1b5ee3eb', // Localhost
        include_player_ids: [gPlayerId],
        contents: {
          en: isAccepted
            ? `Hi, ${gLegal}. ${sLegal} has just accepted your offer.`
            : `Hi, ${gLegal}. ${sLegal} has just declined your offer.`,
        },
      };
      axios
        .post("https://onesignal.com/api/v1/notifications", pushContent)
        .then(function (response) {
        })
        .catch(function (error) {
        });

      const SUBJECT = isAccepted ? "Congratulations" : "Oops";

      const acceptDetails = isAccepted
        ? `${sLegal} has just accepted ${gLegal}'s offer.`
        : `${sLegal} has just declined ${gLegal}'s offer.`;

      const alertDetails = `<!doctype html>
      <html>
        <head>
          <meta name="viewport" content="width=device-width" />
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <title>${isAccepted ? "Congratulations" : "Oops"}</title>
          <style>
            body {
              background-color: #f6f6f6;
              font-family: sans-serif;
              -webkit-font-smoothing: antialiased;
              font-size: 14px;
              line-height: 1.4;
              margin: 0;
              padding: 0;
              -ms-text-size-adjust: 100%;
              -webkit-text-size-adjust: 100%; 
            }
      
            table {
              border-collapse: separate;
              mso-table-lspace: 0pt;
              mso-table-rspace: 0pt;
              width: 100%; }
              table td {
                font-family: sans-serif;
                font-size: 14px;
                vertical-align: top; 
            }
      
            /* -------------------------------------
                BODY & CONTAINER
            ------------------------------------- */
      
            .body {
              background-color: #f6f6f6;
              width: 100%; 
            }
      
            /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
            .container {
              display: block;
              margin: 0 auto !important;
              /* makes it centered */
              max-width: 580px;
              padding: 10px;
              width: 580px; 
            }
      
            /* This should also be a block element, so that it will fill 100% of the .container */
            .content {
              box-sizing: border-box;
              display: block;
              margin: 0 auto;
              max-width: 580px;
              padding: 10px; 
            }
      
            /* -------------------------------------
                HEADER, FOOTER, MAIN
            ------------------------------------- */
            .main {
              background: #ffffff;
              border-radius: 3px;
              width: 100%; 
            }
      
            .wrapper {
              box-sizing: border-box;
              padding: 20px; 
            }
      
            .content-block {
              padding-bottom: 10px;
              padding-top: 10px;
            }
      
            /* -------------------------------------
                TYPOGRAPHY
            ------------------------------------- */
            h1,
            h2,
            h3,
            h4 {
              color: #000000;
              font-family: sans-serif;
              font-weight: 400;
              line-height: 1.4;
              margin: 0;
              margin-bottom: 30px; 
            }
      
            h1 {
              font-size: 35px;
              font-weight: 300;
              text-align: center;
              text-transform: capitalize; 
            }
      
            p,
            ul,
            ol {
              font-family: sans-serif;
              font-size: 14px;
              font-weight: normal;
              margin: 0;
              margin-bottom: 15px; 
            }
              p li,
              ul li,
              ol li {
                list-style-position: inside;
                margin-left: 5px; 
            }
      
            a {
              color: #3498db;
              text-decoration: underline; 
            }
      
            hr {
              border: 0;
              border-bottom: 1px solid #f6f6f6;
              margin: 20px 0; 
            }
      
            /* -------------------------------------
                RESPONSIVE AND MOBILE FRIENDLY STYLES
            ------------------------------------- */
            @media only screen and (max-width: 620px) {
              table[class=body] h1 {
                font-size: 28px !important;
                margin-bottom: 10px !important; 
              }
              table[class=body] p,
              table[class=body] ul,
              table[class=body] ol,
              table[class=body] td,
              table[class=body] span,
              table[class=body] a {
                font-size: 16px !important; 
              }
              table[class=body] .wrapper,
              table[class=body] .article {
                padding: 10px !important; 
              }
              table[class=body] .content {
                padding: 0 !important; 
              }
              table[class=body] .container {
                padding: 0 !important;
                width: 100% !important; 
              }
              table[class=body] .main {
                border-left-width: 0 !important;
                border-radius: 0 !important;
                border-right-width: 0 !important; 
              }
              table[class=body] .btn table {
                width: 100% !important; 
              }
              table[class=body] .btn a {
                width: 100% !important; 
              }
              table[class=body] .img-responsive {
                height: auto !important;
                max-width: 100% !important;
                width: auto !important; 
              }
            }
      
          </style>
        </head>
        <body class="">
          <span class="preheader"></span>
          <div style="display: flex; align-items: center;">
            <img src="https://i.ibb.co/b5WpDdv/logo.png" alt="logo" border="0">
            <p style="font-weight: bold; margin-left: 8px; color: black;">SubWorkNow</p>
          </div>
          <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
            <tr>
              <td>&nbsp;</td>
              <td class="container">
                <div class="content">
      
                  <!-- START CENTERED WHITE CONTAINER -->
                  <table role="presentation" class="main">
      
                    <!-- START MAIN CONTENT AREA -->
                    <tr>
                      <td class="wrapper">
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                          <tr>
                            <td>
                              <p>${
                                isAccepted ? "Congratulations!" : "Oops!"
                              }</p>
                              <p>${acceptDetails}</p>
                              <br/>
                              <p>The associated “Job” information is as follows:</p>
                              <ul>
                                <li>Services Needed: ${job.ServicesNeeded}</li>
                                <li>Location: ${job.Location}</li>
                                <li>Zip Code: ${job.ZipCode}</li>
                                <li>Description: ${job.Description}</li>
                              </ul> 
                              <br/>
                              <p>This information is being sent as a courtesy to our SubWorkNow.com members.</p>
                              <br/>
                              <p>Also please feel free to use the SubWorkNow.com App messaging/chat system to further coordination between both of you.</p>
                              <br/>
                              <p>Again, please do not hesitate to reach out to us with any questions or for assistance in the “Contact Us” section.</p>
                              <br/>
                              <br/>
                              <p>SubWorkNow.com</p>
                              <p>
                                <a>rey@subworknow.com</a>
                              </p>
                              <p>1-844-SUBWORK1 (1-844-782-6691)</p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
      
                  <!-- END MAIN CONTENT AREA -->
                  </table>
                  <!-- END CENTERED WHITE CONTAINER -->
      
                </div>
              </td>
              <td>&nbsp;</td>
            </tr>
          </table>
        </body>
      </html>`;

      const msg = {
        personalizations: [
          {
            from: {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
            to: [
              {
                email: gEmail,
                name: gLegal,
              },
              {
                email: sEmail,
                name: sLegal,
              },
            ],
          },
        ],
        from: {
          email: "rey@subworknow.com",
          name: "SubWorkNow",
        },
        reply_to: {
          email: "rey@subworknow.com",
          name: "SubWorkNow",
        },
        subject: SUBJECT,
        content: [
          {
            type: "text/html",
            value: alertDetails,
          },
        ],
      };

      axios
        .post(
          "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
          msg,
          { headers: { "Content-Type": "application/json" } }
        )
        .then(function (response) {
        })
        .catch(function (error) {
        });

      history.push({
        pathname: "offersuccess",
        state: { isAccepted },
      });
    } catch (error) {
      setIndicator(false);
    }
  };

  const getOfferData = (offer) => {
    setOfferData(offer);
  };

  return (
    <div className="w-full">
      <Navbar getOfferData={getOfferData} />
      <div className="flex flex-col items-center p-4">
        <div className="flex items-center mb-4">
          <img
            src={sendUserIcon || avatar}
            alt="..."
            className="w-16 h-16 rounded-full"
          />
          <div className="ml-4">
            <p>{job.ServicesNeeded}</p>
            <p>Max Bid: ${overall}</p>
            <p>{job.Description}</p>
            <p>
              Location: {job.Location} {job.ZipCode}
            </p>
          </div>
        </div>
        Your Budget:
        <input
          className="mt-2 mb-4 p-2 w-80 md:w-96 border border-black-400"
          name="budget"
          value={budget}
          placeholder=""
          id="budget"
          disabled={true}
        />
        Your Proposal:
        <textarea
          className="mt-2 p-2 w-80 md:w-96 h-36 border border-black-400 mb-4"
          name="proposal"
          value={proposal}
          placeholder=""
          id="proposal"
          disabled={true}
        />
        Your Timeline:
        <input
          className="mt-2 mb-4 p-2 w-80 md:w-96 border border-black-400"
          name="timeline"
          value={timeline}
          placeholder=""
          id="timeline"
          disabled={true}
        />
        Comment:
        <textarea
          className="mt-2 mb-4 p-2 w-80 md:w-96 h-36 border border-black-400"
          name="comment"
          value={description}
          placeholder=""
          id="comment"
          disabled={true}
        />
        <div className="flex items-center justify-evenly">
          <button
            className={`w-24 bg-primary text-white py-2 my-3 rounded-full focus:outline-none mr-4`}
            onClick={(event) => onAcceptPress(event)}
          >
            Accept
          </button>
          <button
            className={`w-24 bg-primary text-white py-2 my-3 rounded-full focus:outline-none mb-4`}
            onClick={(event) => onDeclinePress(event)}
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewOfferPage;
