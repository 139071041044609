import React, { useRef, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import { storage } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import { FadingCircle } from "better-react-spinkit";
import avatar from "../assets/image/pdf_icon.png";

const FilesPage = () => {
  const history = useHistory();
  const {
    state: { neededService, location, zipCode, description },
  } = useLocation();
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [file4, setFile4] = useState("");
  const [file5, setFile5] = useState("");
  const [file6, setFile6] = useState("");
  const [indicator1, setIndicator1] = useState(false);
  const [indicator2, setIndicator2] = useState(false);
  const [indicator3, setIndicator3] = useState(false);
  const [indicator4, setIndicator4] = useState(false);
  const [indicator5, setIndicator5] = useState(false);
  const [indicator6, setIndicator6] = useState(false);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);
  const { currentUser } = useContext(AuthContext);

  const handleUpload1 = () => {
    const fileInputElement = fileInputRef1.current;
    fileInputElement.click();
  };

  const handleUpload2 = () => {
    const fileInputElement = fileInputRef2.current;
    fileInputElement.click();
  };

  const handleUpload3 = () => {
    const fileInputElement = fileInputRef3.current;
    fileInputElement.click();
  };

  const handleUpload4 = () => {
    const fileInputElement = fileInputRef4.current;
    fileInputElement.click();
  };

  const handleUpload5 = () => {
    const fileInputElement = fileInputRef5.current;
    fileInputElement.click();
  };

  const handleUpload6 = () => {
    const fileInputElement = fileInputRef6.current;
    fileInputElement.click();
  };

  const handleChange = async (e, number) => {
    if (e.currentTarget.files !== null) {
      if (number === 1) {
        setIndicator1(true);
      } else if (number === 2) {
        setIndicator2(true);
      } else if (number === 3) {
        setIndicator3(true);
      } else if (number === 4) {
        setIndicator4(true);
      } else if (number === 5) {
        setIndicator5(true);
      } else {
        setIndicator6(true);
      }

      const fileUploaded = e.currentTarget.files[0];
      const fileType = fileUploaded.type || "type";
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate() +
        "-" +
        today.getTime();

      let fileUrl = "";
      if(currentUser.userType === 0) {
        await storage
          .ref("GCT")
          .child(`${currentUser.UserUid}/${date}/${fileType}-file${number}`)
          .put(fileUploaded);
        fileUrl = await storage
          .ref("GCT")
          .child(`${currentUser.UserUid}/${date}/${fileType}-file${number}`)
          .getDownloadURL();
      } else {
        await storage
          .ref("RT")
          .child(`${currentUser.UserUid}/${date}/${fileType}-file${number}`)
          .put(fileUploaded);
        fileUrl = await storage
          .ref("RT")
          .child(`${currentUser.UserUid}/${date}/${fileType}-file${number}`)
          .getDownloadURL();
      }
      if (number === 1) {
        setFile1(fileUrl);
        setIndicator1(false);
      } else if (number === 2) {
        setFile2(fileUrl);
        setIndicator2(false);
      } else if (number === 3) {
        setFile3(fileUrl);
        setIndicator3(false);
      } else if (number === 4) {
        setFile4(fileUrl);
        setIndicator4(false);
      } else if (number === 5) {
        setFile5(fileUrl);
        setIndicator5(false);
      } else {
        setFile6(fileUrl);
        setIndicator6(false);
      }
      toast("File Saved!");
    }
  };

  const onPress = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/budget",
      state: {
        neededService,
        location,
        zipCode,
        description,
        file1,
        file2,
        file3,
        file4,
        file5,
        file6,
      },
    });
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4 px-4">
        <div className="text-center">Upload any docs (images, photos, videos and docs) you wish to be seen.</div>
        <div className="flex justify-around items-center my-3">
          {file1 && file1.includes("image") && (
            <img
              src={file1}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file1.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file1} type="video/mp4" />
            </video>
          )}
          {file1.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file1 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef1}
            onChange={(e) => handleChange(e, 1)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload1(event);
            }}
          >
            {indicator1 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>

        <div className="flex justify-around items-center my-3">
          {file2 && file2.includes("image") && (
            <img
              src={file2}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file2.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file2} type="video/mp4" />
            </video>
          )}
          {file2.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file2 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef2}
            onChange={(e) => handleChange(e, 2)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload2(event);
            }}
          >
            {indicator2 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>

        <div className="flex justify-around items-center my-3">
          {file3 && file3.includes("image") && (
            <img
              src={file3}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file3.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file3} type="video/mp4" />
            </video>
          )}
          {file3.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file3 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef3}
            onChange={(e) => handleChange(e, 3)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload3(event);
            }}
          >
            {indicator3 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>

        <div className="flex justify-around items-center my-3">
          {file4 && file4.includes("image") && (
            <img
              src={file4}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file4.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file4} type="video/mp4" />
            </video>
          )}
          {file4.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file4 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef4}
            onChange={(e) => handleChange(e, 4)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload4(event);
            }}
          >
            {indicator4 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>

        <div className="flex justify-around items-center my-3">
          {file5 && file5.includes("image") && (
            <img
              src={file5}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file5.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file5} type="video/mp4" />
            </video>
          )}
          {file5.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file5 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef5}
            onChange={(e) => handleChange(e, 5)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload5(event);
            }}
          >
            {indicator5 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>

        <div className="flex justify-around items-center my-3">
          {file6 && file6.includes("image") && (
            <img
              src={file6}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file6.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file6} type="video/mp4" />
            </video>
          )}
          {file6.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file6 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef6}
            onChange={(e) => handleChange(e, 6)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload6(event);
            }}
          >
            {indicator6 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>
        <button
          className={`w-48 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={(event) => {
            onPress(event);
          }}
        >
          Next
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default FilesPage;
