import React, { useState } from "react";

const Tooltip = ({ children, text, boxStyle = {width: 180}, ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <div style={toolTipStyles.tooltipContainer}>
      <div
        style={
          show
            ? { ...toolTipStyles.tooltipBox, ...boxStyle, display: "block" }
            : {...toolTipStyles.tooltipBox, ...boxStyle}
        }
      >
        {text}
        <span style={toolTipStyles.tooltipArrow} />
      </div>
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};

const toolTipStyles = {
  tooltipContainer: {
    position: "relative",
  },
  tooltipBox: {
    position: "absolute",
    background: "rgba(0, 0, 0, 0.7)",
    color: "#fff",
    padding: 5,
    borderRadius: 5,
    top: "calc(100% + 5px)",
    display: "none",
    fontSize: 12,
    width: 180,
    left: -70,
    textAlign: "center",
  },
  tooltipArrow: {
    position: "absolute",
    top: -10,
    left: "50%",
    borderWidth: 5,
    borderStyle: "solid",
    borderColor: "transparent transparent rgba(0, 0, 0, 0.7) transparent",
  },
};

export default Tooltip;
