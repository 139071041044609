import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../providers/Authentication";
import { database } from "../firebase";
import avatar from "../assets/image/avatar.png";
import { ThreeBounce } from "better-react-spinkit";

const CurrentBidsPage = () => {
  const {
    state: { job },
  } = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);

  const [indicator, setIndicator] = useState(false);
  const [bids, setBids] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIndicator(true);
      const gEmail = currentUser.Email;
      const gLegal = currentUser.LegalEntity;
      const gPoint = currentUser.PointOfContact;
      const gPhone = currentUser.Phone;

      let tempBids = [];
      let updatedBids = [];

      await database.ref("BT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            tempBids.push(child.val());
          });
        }
      });

      let item = {};
      for (item of tempBids) {
        let hireAvailability = false;
        let tempUserId = item.UserUid;
        const snapshot1 = await database.ref(`SCT/${tempUserId}`).once("value");
        if (!!snapshot1.val()) {
          const icon = snapshot1.val().IconUpload; // a user's avatar that post this job
          const sEmail = snapshot1.val().Email; // a user's email that post this job
          const sLegal = snapshot1.val().LegalEntityName;
          const sPlayerId = snapshot1.val().PlayerId;
          const sLicense = snapshot1.val().LicenseNumber;
          const sPoint = snapshot1.val().PointOfContact;
          const sPhone = snapshot1.val().Phone;
          const sWebsite = snapshot1.val().Website;
          const sUid = item.UserUid;
          let tempBidId = item.BidId;
          await database
            .ref("OT")
            .once("value", (snapshot3) => {
              if (!!snapshot3.val()) {
                snapshot3.forEach((child) => {
                  if (
                    tempBidId === child.val().BidId &&
                    currentUser.UserUid === child.val().UserUid
                  ) {
                    hireAvailability = true;
                  }
                });
              }
            })
            .then(() => {
              if (job.JobId === item.JobId) {
                const newItem = {
                  ...item,
                  icon,
                  sEmail,
                  gEmail,
                  sLegal,
                  gLegal,
                  sPlayerId,
                  hireAvailability,
                  gPoint,
                  gPhone,
                  sLicense,
                  sPoint,
                  sPhone,
                  sWebsite,
                  sUid,
                };
                updatedBids.push(newItem);
              }
            });
        }
      }
      setBids(updatedBids);
      setIndicator(false);
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const onPressViewSC = (e, bid) => {
    e.preventDefault();
    history.push({
      pathname: "viewsc",
      state: { bid },
    });
  };

  const onPressHire = (e, bid) => {
    e.preventDefault();
    history.push({
      pathname: "offer",
      state: { bid, job },
    });
  };

  return (
    <div className="w-full">
      <Navbar />
      {indicator ? (
        <div className="flex justify-center items-center flex-col my-80">
          <ThreeBounce size={24} color="#03a9f4" />
          Please wait...
        </div>
      ) : (
        <div className="flex flex-col items-center py-4">
          {bids.length === 0 ? (
            <p>Any posted Bids will appear momentarily.</p>
          ) : (
            bids.map((bid) => (
              <div className="w-96 border px-4 py-2 my-2" key={bid.BidId}>
                <div className="flex items-center">
                  <img
                    src={bid.icon ? bid.icon : avatar}
                    alt="..."
                    className="w-16 h-16 rounded-full"
                  />
                  <div className="ml-4">
                    <p>{job.ServicesNeeded}</p>
                    <p>{bid.Proposal}</p>
                    <p>Price Range: ${bid.Budget}</p>
                    <p>Timeline: {bid.Timeline}</p>
                  </div>
                </div>
                <div className="flex items-center justify-evenly">
                  <button
                    className={`w-24 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
                    onClick={(event) => onPressViewSC(event, bid)}
                  >
                    View SC
                  </button>
                  <button
                    className={`w-24 ${
                      bid.hireAvailability ? "bg-gray-500" : "bg-primary"
                    } text-white py-2 my-3 rounded-full focus:outline-none`}
                    onClick={(event) => onPressHire(event, bid)}
                    disabled={bid.hireAvailability}
                  >
                    Select
                  </button>
                </div>
              </div>
            ))
          )}
          <button
            className='w-32 bg-primary text-white py-2 my-3 rounded-full focus:outline-none'
            onClick={() => history.goBack()}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default CurrentBidsPage;
