import React, {useEffect, useState, useContext} from 'react';
import Navbar from "../components/Navbar";
import queryString from 'query-string';
import { AuthContext } from "../providers/Authentication";
import axios from "axios";
import { ThreeBounce } from "better-react-spinkit";
import { useHistory } from "react-router-dom";

const OAuthRedirect  = () => {
  const { currentUser } = useContext(AuthContext);
  const [indicator, setIndicator] = useState(false);
  const [code, setCode] = useState("");
  const history = useHistory();

  useEffect(() => {
    //Parsing over URL
    const value=queryString.parse(window.location.search);
    const code=value.code;
    console.log('code:', code)
    setCode(code);
  }, []);

  const completeConnectGetAccount = () => {
    setIndicator(true);
    axios
      .post(
        "https://us-central1-subworknow.cloudfunctions.net/oAuthGetStripeAccount",
        // "http://localhost:5001/subworknow/us-central1/oAuthGetStripeAccount",
        {
          userUid: currentUser.UserUid,
          code: code
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function (response) {
        console.log("oAuth Get Account response=>", response);
        setIndicator(false);
        const data = response.data;
        const status = response.status;
        if(status === 200) {
          let accountId = data.stripe_user_id;
          history.push({
            pathname: "escrowtransfer",
            state: { accountId },
          });
        } else {
        }
      })
      .catch(function (error) {
        setIndicator(false);
      });
  }

  return (
    <div className="w-full">
      <Navbar />
      {indicator &&
        <div className="flex justify-center items-center flex-col my-4">
          <ThreeBounce size={24} color="#03a9f4" />
          Please wait...
        </div>
      }
      <div className="flex justify-center items-center py-4">
        Escrow Process
      </div>
      <button
        className={`bg-primary flex justify-center mx-auto w-4/5 md:w-1/5 mt-6 py-2 text-white rounded`}
        onClick={(event) => {
          completeConnectGetAccount(event);
        }}
      >
        Complete the connection and get the account ID
      </button>
    </div>
  );
};

export default OAuthRedirect;