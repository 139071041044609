import React from "react";
import avatar from "../assets/image/pdf_icon.png";

const JobDetailPage = ({ job, onCloseView }) => {
  const onPress = (e) => {
    e.preventDefault();
    onCloseView();
  };

  const openFile = (e, file) => {
    e.preventDefault();
    const win = window.open(file, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  return (
    <>
      <div className="flex flex-col items-center p-4">
        Services Needed:
        <input
          className="mt-2 mb-4 p-2 w-80 md:w-96 border border-black-400 text-center"
          value={job.ServicesNeeded || ""}
          readOnly
        />
        Location:
        <input
          className="mt-2 mb-4 p-2 w-80 md:w-96 border border-black-400 text-center"
          value={job.Location || ""}
          readOnly
        />
        Zip Code:
        <input
          className="mt-2 mb-4 p-2 w-80 md:w-96 border border-black-400 text-center"
          value={job.ZipCode || ""}
          readOnly
        />
        Description:
        <textarea
          className="mt-2 mb-4 p-2 w-80 md:w-96 h-36 border border-black-400"
          value={job.Description || ""}
          readOnly
        />
        {job.Upload &&
          (job.Upload.file1 ||
            job.Upload.file2 ||
            job.Upload.file3 ||
            job.Upload.file4 ||
            job.Upload.file5 ||
            job.Upload.file6) &&
          "Uploading Files:"}
        {job.Upload && job.Upload.file1 && (
          <div className="flex justify-center my-3 w-96">
            {job.Upload.file1.includes("image") && (
              <img
                src={job.Upload.file1}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            {job.Upload.file1.includes("video") && (
              <video
                autoPlay
                muted
                loop
                className="border rounded-lg h-32 w-32"
              >
                <source src={job.Upload.file1} type="video/mp4" />
              </video>
            )}
            {job.Upload.file1.includes("pdf") && (
              <img
                src={avatar}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            <button
              className={`w-24 bg-primary text-white py-2 my-3 mx-3 rounded-full focus:outline-none self-center`}
              onClick={(event) => {
                openFile(event, job.Upload.file1);
              }}
            >
              View
            </button>
          </div>
        )}
        {job.Upload && job.Upload.file2 && (
          <div className="flex justify-center my-3 w-96">
            {job.Upload.file2.includes("image") && (
              <img
                src={job.Upload.file2}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            {job.Upload.file2.includes("video") && (
              <video
                autoPlay
                muted
                loop
                className="border rounded-lg h-32 w-32"
              >
                <source src={job.Upload.file2} type="video/mp4" />
              </video>
            )}
            {job.Upload.file2.includes("pdf") && (
              <img
                src={avatar}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            <button
              className={`w-24 bg-primary text-white py-2 my-3 mx-3 rounded-full focus:outline-none self-center`}
              onClick={(event) => {
                openFile(event, job.Upload.file2);
              }}
            >
              View
            </button>
          </div>
        )}
        {job.Upload && job.Upload.file3 && (
          <div className="flex justify-center my-3 w-96">
            {job.Upload.file3.includes("image") && (
              <img
                src={job.Upload.file3}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            {job.Upload.file3.includes("video") && (
              <video
                autoPlay
                muted
                loop
                className="border rounded-lg h-32 w-32"
              >
                <source src={job.Upload.file3} type="video/mp4" />
              </video>
            )}
            {job.Upload.file3.includes("pdf") && (
              <img
                src={avatar}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            <button
              className={`w-24 bg-primary text-white py-2 my-3 mx-3 rounded-full focus:outline-none self-center`}
              onClick={(event) => {
                openFile(event, job.Upload.file3);
              }}
            >
              View
            </button>
          </div>
        )}
        {job.Upload && job.Upload.file4 && (
          <div className="flex justify-center my-3 w-96">
            {job.Upload.file4.includes("image") && (
              <img
                src={job.Upload.file4}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            {job.Upload.file4.includes("video") && (
              <video
                autoPlay
                muted
                loop
                className="border rounded-lg h-32 w-32"
              >
                <source src={job.Upload.file4} type="video/mp4" />
              </video>
            )}
            {job.Upload.file4.includes("pdf") && (
              <img
                src={avatar}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            <button
              className={`w-24 bg-primary text-white py-2 my-3 mx-3 rounded-full focus:outline-none self-center`}
              onClick={(event) => {
                openFile(event, job.Upload.file4);
              }}
            >
              View
            </button>
          </div>
        )}
        {job.Upload && job.Upload.file5 && (
          <div className="flex justify-center my-3 w-96">
            {job.Upload.file5.includes("image") && (
              <img
                src={job.Upload.file5}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            {job.Upload.file5.includes("video") && (
              <video
                autoPlay
                muted
                loop
                className="border rounded-lg h-32 w-32"
              >
                <source src={job.Upload.file5} type="video/mp4" />
              </video>
            )}
            {job.Upload.file5.includes("pdf") && (
              <img
                src={avatar}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            <button
              className={`w-24 bg-primary text-white py-2 my-3 mx-3 rounded-full focus:outline-none self-center`}
              onClick={(event) => {
                openFile(event, job.Upload.file5);
              }}
            >
              View
            </button>
          </div>
        )}
        {job.Upload && job.Upload.file6 && (
          <div className="flex justify-center my-3 w-96">
            {job.Upload.file6.includes("image") && (
              <img
                src={job.Upload.file6}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            {job.Upload.file6.includes("video") && (
              <video
                autoPlay
                muted
                loop
                className="border rounded-lg h-32 w-32"
              >
                <source src={job.Upload.file6} type="video/mp4" />
              </video>
            )}
            {job.Upload.file6.includes("pdf") && (
              <img
                src={avatar}
                alt="..."
                className="border rounded-lg h-32 w-32 object-contain"
              />
            )}
            <button
              className={`w-24 bg-primary text-white py-2 my-3 mx-3 rounded-full focus:outline-none self-center`}
              onClick={(event) => {
                openFile(event, job.Upload.file6);
              }}
            >
              View
            </button>
          </div>
        )}
        Maximum Offer:
        <input
          type="text"
          className="my-2 p-1 focus:outline-none font-bold border text-primary font-bold text-2xl w-80 md:w-96 text-center"
          value={`$${job.Payment ? job.Payment.Overall : ""}`}
          readOnly
        />
        <div className="flex items-center mt-4">
          <input
            type="radio"
            name="first"
            value="first"
            checked={!job.Payment.phase1}
            readOnly
          />
          &nbsp;&nbsp;Overall Payment
        </div>
        <div className="flex items-center mt-4">
          <input
            type="radio"
            name="second"
            value="second"
            checked={job.Payment.phase1}
            readOnly
          />
          &nbsp;&nbsp;Several Smaller Payments
        </div>
        <div className="flex items-center">
          Phase 1 Completion:&nbsp;&nbsp;&nbsp;
          <div className="flex items-center text-primary">
            $&nbsp;
            <input
              type="text"
              className="my-2 p-1 focus:outline-none border"
              name="phase1"
              value={job.Payment ? job.Payment.phase1 : ""}
              placeholder=""
              id="phase1"
              disabled={true}
            />
          </div>
        </div>
        <div className="flex items-center">
          Phase 2 Completion:&nbsp;&nbsp;&nbsp;
          <div className="flex items-center text-primary">
            $&nbsp;
            <input
              type="text"
              className="my-2 p-1 focus:outline-none border"
              name="phase2"
              value={job.Payment ? job.Payment.phase2 : ""}
              placeholder=""
              id="phase2"
              disabled={true}
            />
          </div>
        </div>
        <div className="flex items-center">
          Phase 3 Completion:&nbsp;&nbsp;&nbsp;
          <div className="flex items-center text-primary">
            $&nbsp;
            <input
              type="text"
              className="my-2 p-1 focus:outline-none border"
              name="phase3"
              value={job.Payment ? job.Payment.phase3 : ""}
              placeholder=""
              id="phase3"
              disabled={true}
            />
          </div>
        </div>
        <button
          className={`w-48 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={(event) => {
            onPress(event);
          }}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default JobDetailPage;
