import React, { useEffect, useState } from "react";
import { auth, database } from "../firebase";
import OneSignal from "react-onesignal";
import { ThreeBounce } from "better-react-spinkit";
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        let playerId = "";
        try {
          playerId = await OneSignal.getPlayerId();
        } catch (err) {
          playerId = "";
        }

        let address = "";
        address = await localStorage.getItem('address');

        setTimeout(() => {
          const gcUserRef = database.ref(`GCT/${user.uid}`);
          gcUserRef.get().then(async (snapshot) => {
            if (snapshot.exists()) {
              await gcUserRef.update({
                UserUid: user.uid,
                PlayerId: playerId || snapshot.val().PlayerId || "",
                Address: snapshot.val().Address || address || "",
              });
              setCurrentUser({
                ...snapshot.val(),
                userType: 0,
                UserUid: user.uid,
                PlayerId: playerId || snapshot.val().PlayerId || "",
              });
              setPending(false);
            } else {
              const scUserRef = database.ref(`SCT/${user.uid}`);
              scUserRef.get().then(async (snapshot1) => {
                if (snapshot1.exists()) {
                  await scUserRef.update({
                    UserUid: user.uid,
                    PlayerId: playerId || snapshot1.val().PlayerId || "",
                    Address: snapshot1.val().Address || address || "",
                  });
                  setCurrentUser({
                    ...snapshot1.val(),
                    userType: 1,
                    UserUid: user.uid,
                    PlayerId: playerId || snapshot1.val().PlayerId || "",
                  });
                  setPending(false);
                } else {
                  const rUserRef = database.ref(`RT/${user.uid}`);
                  rUserRef.get().then(async (snapshot2) => {
                    if (snapshot2.exists()) {
                      await rUserRef.update({
                        UserUid: user.uid,
                        PlayerId: playerId || snapshot2.val().PlayerId || "",
                        Address: snapshot2.val().Address || address || "",
                      });
                      setCurrentUser({
                        ...snapshot2.val(),
                        userType: 2,
                        UserUid: user.uid,
                        PlayerId: playerId || snapshot2.val().PlayerId || "",
                      });
                      setPending(false);
                    } else {
                      setCurrentUser(null);
                      setPending(false);
                    }
                  });
                }
              });
            }
          });
        }, 500);
      } else {
        setCurrentUser(null);
        setPending(false);
      }
    });
  }, []);

  if (pending) {
    return (
      <div className="flex justify-center items-center flex-col h-screen">
        <ThreeBounce size={24} color="#03a9f4" />
        Please wait...
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
