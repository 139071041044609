import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import { firestore, database, analytics } from "../firebase";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-dropdown";

const AddRoomPage = () => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [indicator, setIndicator] = useState(false);
  const [roomName, setRoomName] = useState("");
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIndicator(true);
      let tempJobs = [];
      let updatedJobs = [];
      let availableBidJobs = [];
      await database.ref("JT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            if (child.val().UserUid === currentUser.UserUid) {
              tempJobs.push(child.val());
            }
          });
        }
      });

      let item = {};
      for (item of tempJobs) {
        let bidUserId = "";
        let tempJobId = item.JobId;
        await database
          .ref("BT")
          .once("value", (snapshot3) => {
            if (!!snapshot3.val()) {
              snapshot3.forEach((child) => {
                if (tempJobId === child.val().JobId) {
                  bidUserId = child.val().UserUid;
                }
              });
            }
          })
          .then(() => {
            if (bidUserId) {
              const newItem = { ...item, bidUserId };
              updatedJobs.push(newItem);
            }
          });
      }

      let item1 = {};
      for (item1 of updatedJobs) {
        const snapshot1 = await database
          .ref(`SCT/${item1.bidUserId}`)
          .once("value");
        let legalEntity = snapshot1.val().LegalEntityName;
        const newItem1 = { ...item1, legalEntity };
        availableBidJobs.push(newItem1);
      }

      setJobs(availableBidJobs);
      setIndicator(false);
      if (availableBidJobs.length === 0) {
        toast(
          "Unfortunately, you couldn't create a chat room because any SC has yet to bid on your job."
        );
        setTimeout(() => history.goBack(), 2000);
      }
    }

    analytics.logEvent("add_room_page");
    fetchData();
    // eslint-disable-next-line
  }, []);

  /**
   * Create a new Firestore collection to save threads
   */
  function handleButtonPress() {
    if (roomName.length > 0) {
      firestore
        .collection(currentUser.UserUid)
        .add({
          name: roomName,
          latestMessage: {
            text: `You have joined the room ${roomName
              .split(":")[0]
              .trim()}:${roomName.split(":")[1].trim()}.`,
            createdAt: new Date().getTime(),
          },
        })
        .then((docRef) => {
          docRef.collection("MESSAGES").add({
            text: `You have joined the room ${roomName
              .split(":")[0]
              .trim()}:${roomName.split(":")[1].trim()}.`,
            createdAt: new Date().getTime(),
            system: true,
          });
        });
      history.goBack();
    }
  }

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        Create a new chat room
        <Dropdown
          options={jobs.map((item) => {
            return {
              label: `${item.legalEntity} : ${item.ServicesNeeded}: ${item.Location} : ${item.Description}`,
              value: `${item.legalEntity}:${item.ServicesNeeded}:${item.bidUserId}:${item.Location}:${item.Description}`,
            };
          })}
          onChange={(value) => setRoomName(value.value)}
          value={""}
          placeholder="Choose one..."
          className="mt-2 mb-4 w-96 mr-4"
        />
        <button
          className={`w-32 ${
            roomName.length > 0 ? "bg-primary" : "bg-gray-500"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={() => handleButtonPress()}
          disabled={roomName.length === 0}
        >
          Create
        </button>
        <button
          className={`w-32 bg-primary text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={() => history.goBack()}
        >
          Close
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddRoomPage;
