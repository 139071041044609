import React from "react";
import Navbar from "../components/Navbar";

const AMPage = () => {
  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        Account Management Page
      </div>
    </div>
  );
};

export default AMPage;
