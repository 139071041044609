import React, { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { Redirect } from "react-router";
import { analytics, auth } from "../firebase";
import { AuthContext } from "../providers/Authentication";

const SignIn = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isShowPwd, setIsShowPwd] = useState(false);

  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    return <Redirect to="/" />;
  }

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    analytics.logEvent('sign_in_button');
    auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        setError(null);
        history.push("/");
      })
      .catch((error) => {
        setError("Error signing in with password and email!");
        console.error("Error signing in with password and email", error);
      });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  const showPwd = (e) => {
    e.preventDefault();
    setIsShowPwd(!isShowPwd);
  };

  return (
    <div className="mt-8">
      <h1 className="text-3xl mb-2 text-center font-bold">Sign In</h1>
      <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        {error !== null && (
          <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <form className="">
          <label htmlFor="userEmail" className="block">
            Email:
          </label>
          <input
            type="email"
            className="my-1 p-1 w-full border border-black-400"
            name="userEmail"
            value={email}
            placeholder="Your Email"
            id="userEmail"
            onChange={(event) => onChangeHandler(event)}
          />
          <label htmlFor="userPassword" className="block">
            Password:
          </label>
          <div className="relative">
            <input
              type={isShowPwd ? "text" : "password"}
              className="mt-1 mb-3 p-1 w-full border border-black-400"
              name="userPassword"
              value={password}
              placeholder="Your Password"
              id="userPassword"
              onChange={(event) => onChangeHandler(event)}
            />
            {!isShowPwd ? (
              <i
                className="fa fa-eye text-lg leading-lg opacity-75 absolute right-2 top-2 cursor-pointer"
                onClick={showPwd}
              ></i>
            ) : (
              <i
                className="fa fa-eye-slash text-lg leading-lg opacity-75 absolute right-2 top-2 cursor-pointer"
                onClick={showPwd}
              ></i>
            )}
          </div>
          <button
            className="bg-primary w-full py-2 text-white"
            onClick={(event) => {
              signInWithEmailAndPasswordHandler(event, email, password);
            }}
          >
            Sign in
          </button>
        </form>
        <p className="text-center my-3">
          Don't have an account?{" "}
          <Link to="signUp" className="text-primary">
            Sign up here
          </Link>{" "}
          <br />{" "}
          <Link to="passwordReset" className="text-primary">
            Forgot Password?
          </Link>
        </p>
      </div>
    </div>
  );
};
export default SignIn;
