import React, { useState, useContext, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../providers/Authentication";
import { analytics, database } from "../firebase";
import axios from "axios";

const W9Page = () => {
  const { currentUser } = useContext(AuthContext);
  const [answer1, setAnswer1] = useState(
    currentUser.W9 ? currentUser.W9.answer1 : ""
  );
  const [answer2, setAnswer2] = useState(
    currentUser.W9 ? currentUser.W9.answer2 : ""
  );
  const [answer3, setAnswer3] = useState(
    currentUser.W9 ? currentUser.W9.answer3 : ""
  );
  const [answer4, setAnswer4] = useState(
    currentUser.W9 ? currentUser.W9.answer4 : ""
  );
  const [answer5, setAnswer5] = useState(
    currentUser.W9 ? currentUser.W9.answer5 : ""
  );
  const [answer6, setAnswer6] = useState(
    currentUser.W9 ? currentUser.W9.answer6 : ""
  );
  const [answer7, setAnswer7] = useState(
    currentUser.W9 ? currentUser.W9.answer7 : ""
  );
  const [ssn, setSSN] = useState(currentUser.W9 ? currentUser.W9.ssn : "");
  const [ein, setEIN] = useState(currentUser.W9 ? currentUser.W9.ein : "");
  const [isSelected, setSelection] = useState(false);
  const [employees, setEmployees] = useState(
    currentUser.W9 ? currentUser.W9.employees : ""
  );
  const history = useHistory();

  useEffect(() => {
    analytics.logEvent('w9_page');
  });

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "answer1") {
      setAnswer1(value);
    } else if (name === "answer2") {
      setAnswer2(value);
    } else if (name === "answer3") {
      setAnswer3(value);
    } else if (name === "answer4") {
      setAnswer4(value);
    } else if (name === "answer5") {
      setAnswer5(value);
    } else if (name === "answer6") {
      setAnswer6(value);
    } else if (name === "answer7") {
      setAnswer7(value);
    } else if (name === "ssn") {
      setSSN(value);
    } else if (name === "ein") {
      setEIN(value);
    } else if (name === "employees") {
      setEmployees(value);
    }
  };

  const onPress = (e) => {
    e.preventDefault();
    const scUserRef = database.ref(`SCT/${currentUser.UserUid}`);
    scUserRef
      .update({
        W9: {
          answer1,
          answer2,
          answer3,
          answer4,
          answer5,
          answer6,
          answer7,
          ssn,
          ein,
          employees,
        },
      })
      .then(() => {
        const msg = {
          personalizations: [
            {
              from: {
                email: "rey@subworknow.com",
                name: "SubWorkNow",
              },
              to: [
                {
                  email: currentUser.Email,
                  name: currentUser.LegalEntity,
                },
              ],
            },
          ],
          from: {
            email: "rey@subworknow.com",
            name: "SubWorkNow",
          },
          reply_to: {
            email: "rey@subworknow.com",
            name: "SubWorkNow",
          },
          subject: "Congratulations",
          content: [
            {
              type: "text/html",
              value: `<!doctype html>
              <html>
                <body>
                  <div style="display: flex; align-items: center;">
                    <img src="https://i.ibb.co/b5WpDdv/logo.png" alt="logo" border="0">
                    <p style="font-weight: bold; margin-left: 8px; color: black;">SubWorkNow</p>
                  </div>
                  <p>Thank you for registering your information.</p>
                  <p>If selected for a job your information will be forwarded to your General Contractor or Realtor.</p>
                </body>
              </html>
            `,
            },
          ],
        };

        axios
          .post(
            "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
            msg,
            { headers: { "Content-Type": "application/json" } }
          )
          .then(function (response) {
          })
          .catch(function (error) {
          });
        history.push("/w9success");
      });
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col py-4 px-8">
        If completed, upon job selection these items will automatically be
        forwarded to the General Contractor or Realtor in order to save your business time
        and effort.
        <p className="mt-8 text-xl uppercase">For subcontractor to complete</p>
        1. Individual or Business Name (as shown your income tax return):
        <input
          className="mt-2 p-2 border-b focus:outline-none border-gray-600 mb-4"
          name="answer1"
          value={answer1}
          placeholder=""
          id="answer1"
          onChange={(event) => onChangeHandler(event)}
        />
        2. Business name:
        <input
          className="mt-2 p-2 border-b focus:outline-none border-gray-600 mb-4"
          name="answer2"
          value={answer2}
          placeholder=""
          id="answer2"
          onChange={(event) => onChangeHandler(event)}
        />
        3. Check appropriate box for federal tax classification of the name
        entered above. Check only one of the following seven:
        <label className="mt-2">
          <input
            type="checkbox"
            name="answer3-0"
            checked={answer3 === 0}
            onChange={() => setAnswer3(answer3 === 0 ? null : 0)}
          />
          &nbsp;&nbsp;&nbsp;Individual/sole proprietor or single-member LLC
        </label>
        <label className="mt-2">
          <input
            type="checkbox"
            name="answer3-1"
            checked={answer3 === 1}
            onChange={() => setAnswer3(answer3 === 1 ? null : 1)}
          />
          &nbsp;&nbsp;&nbsp;C Corporation
        </label>
        <label className="mt-2">
          <input
            type="checkbox"
            name="answer3-2"
            checked={answer3 === 2}
            onChange={() => setAnswer3(answer3 === 2 ? null : 2)}
          />
          &nbsp;&nbsp;&nbsp;S Corporation
        </label>
        <label className="mt-2">
          <input
            type="checkbox"
            name="answer3-3"
            checked={answer3 === 3}
            onChange={() => setAnswer3(answer3 === 3 ? null : 3)}
          />
          &nbsp;&nbsp;&nbsp;Partnership
        </label>
        <label className="mt-2">
          <input
            type="checkbox"
            name="answer3-4"
            checked={answer3 === 4}
            onChange={() => setAnswer3(answer3 === 4 ? null : 4)}
          />
          &nbsp;&nbsp;&nbsp;Limited Liability Company - Enter the tax
          classification (C=C Corporation, S=S corporation,
          P=Partnership):&nbsp;&nbsp;
          <input
            className="border-b focus:outline-none border-gray-600 w-8"
            name="answer3"
            value={typeof answer3 === "string" ? answer3 : ""}
            placeholder=""
            id="answer3"
            onChange={(event) => onChangeHandler(event)}
          />
        </label>
        <label className="mt-2 mb-4">
          <input
            type="checkbox"
            name="answer3-6"
            checked={answer3 === 6}
            onChange={() => setAnswer3(answer3 === 6 ? null : 6)}
          />
          &nbsp;&nbsp;&nbsp;Other
        </label>
        4. List any applicable exemptions codes:
        <input
          className="mt-2 p-2 border-b focus:outline-none border-gray-600 mb-4"
          name="answer4"
          value={answer4}
          placeholder=""
          id="answer4"
          onChange={(event) => onChangeHandler(event)}
        />
        5. Address (number, street, and apt. or suite no.):
        <input
          className="mt-2 p-2 border-b focus:outline-none border-gray-600 mb-4"
          name="answer5"
          value={answer5}
          placeholder=""
          id="answer5"
          onChange={(event) => onChangeHandler(event)}
        />
        6. City, state, and zip code:
        <input
          className="mt-2 p-2 border-b focus:outline-none border-gray-600 mb-4"
          name="answer6"
          value={answer6}
          placeholder=""
          id="answer6"
          onChange={(event) => onChangeHandler(event)}
        />
        7. List account number(s) here (optional):
        <input
          className="mt-2 p-2 border-b focus:outline-none border-gray-600 mb-4"
          name="answer7"
          value={answer7}
          placeholder=""
          id="answer7"
          onChange={(event) => onChangeHandler(event)}
        />
        <p className="font-bold">Requester's name and address (optional): </p>
        Taxpayer Identification Number:
        <br />
        (Social Security Number):
        <input
          type='number'
          className="mt-2 p-2 border-b focus:outline-none border-gray-600 mb-4"
          name="ssn"
          value={ssn}
          placeholder="123-45-6789"
          id="ssn"
          onChange={(event) => onChangeHandler(event)}
        />
        (Employer Identification Number):
        <input
          type='number'
          className="mt-2 p-2 border-b focus:outline-none border-gray-600 mb-4"
          name="ein"
          value={ein}
          placeholder="12-3456789"
          id="ein"
          onChange={(event) => onChangeHandler(event)}
        />
        <p className="font-bold">Establishment of Independent Relationship</p>
        <label className="mt-2 mb-4">
          <input
            type="checkbox"
            name="answer3-6"
            checked={isSelected}
            onChange={() => setSelection(!isSelected)}
          />
          &nbsp;&nbsp;&nbsp;Accept terms
        </label>
        {isSelected && (
          <div className="border p-2">
            <p>
              I hereby declare that I meet the qualifications of being an
              independent contractor;
            </p>
            <p>I am operating as an independent contractor;</p>
            <p>
              I assume the responsibilities as an employer for the work
              completed; and that I nor my employees are employees of the
              General Contractor or Realtor.
            </p>
          </div>
        )}
        The estimated number of applicable employees (or range of employees)
        utilized for job completion?
        <input
          className="mt-2 p-2 border-b focus:outline-none border-gray-600 mb-4"
          name="employees"
          value={employees}
          placeholder=""
          id="employees"
          onChange={(event) => onChangeHandler(event)}
        />
        <button
          className={`w-24 ${isSelected ? 'bg-primary' : 'bg-gray-500'} text-white py-2 my-3 rounded-full focus:outline-none self-center`}
          onClick={(event) => onPress(event)}
          disabled={!isSelected}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default W9Page;
