import React, { useEffect } from "react";
import { AuthProvider } from "./providers/Authentication";
import PublicRoute from "./routes/PublicRoute";
import OneSignal from "react-onesignal";
import axios from "axios";
import { database } from "./firebase";

var api_key = 'eb84f3d49c104f289c4afae946b813fa';

function App() {
  useEffect(() => {
    OneSignal.initialize("f6696b79-ba9e-4042-a68e-d3c38bb2a741"); // Live
    // OneSignal.initialize('e048cef2-fc4d-4cfc-aea0-c3ad1b5ee3eb'); // Localhost

    const fetchData = async () => {
      let address = "";
      address = await localStorage.getItem('address');

      navigator.geolocation.getCurrentPosition(position => {
        var api_url = 'https://api.opencagedata.com/geocode/v1/json';

        var request_url =
          api_url +
          '?' +
          'key=' +
          api_key +
          '&q=' +
          encodeURIComponent(position.coords.latitude + ',' + position.coords.longitude) +
          '&pretty=1' +
          '&no_annotations=1';
        axios
          .get(request_url)
          .then(async (response) => {
            if (response.data && response.data.results && response.data.results.length > 0 && address !== response.data.results[0].formatted) {
              localStorage.setItem('address', response.data.results[0].formatted);
              const postKey = database.ref().child("LT").push().key;
              await database.ref(`LT/${postKey}`).set({
                Location: response.data.results[0].formatted,
                LocationId: postKey,
                Timestamp: (new Date()).toISOString(),
              });
            }
          })
          .catch(function (error) {
            console.log('error', error);
          });
      }, error => {
        console.log('errror', error);
      });
    }

    fetchData();
  }, []);

  return (
    <AuthProvider>
      <PublicRoute />
    </AuthProvider>
  );
}

export default App;
