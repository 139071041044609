import React, { useState, useEffect, useContext } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import "./CheckoutForm.css";
import { database } from "../firebase";
import { AuthContext } from "../providers/Authentication";

export default function CheckoutForm({clientSecret, checkedEndContracts}) {
  const { currentUser } = useContext(AuthContext);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  // For Debit-Card Payment

  // const cardStyle = {
  //   style: {
  //     base: {
  //       color: "#32325d",
  //       fontFamily: 'Arial, sans-serif',
  //       fontSmoothing: "antialiased",
  //       fontSize: "16px",
  //       "::placeholder": {
  //         color: "#32325d"
  //       }
  //     },
  //     invalid: {
  //       color: "#fa755a",
  //       iconColor: "#fa755a"
  //     }
  //   }
  // };

  // const handleChange = async (event) => {
  //   // Listen for changes in the CardElement
  //   // and display any errors as the customer types their card details
  //   setDisabled(event.empty);
  //   setError(event.error ? event.error.message : "");
  // };

  // const handleSubmit = async ev => {
  //   ev.preventDefault();
  //   setProcessing(true);
  //   console.log("==>", elements.getElement(CardElement));

  //   const payload = await stripe.confirmCardPayment(clientSecret, {
  //     payment_method: {
  //       card: elements.getElement(CardElement)
  //     }
  //   });

  //   console.log("payload=>", payload)

  //   if (payload.error) {
  //     setError(`Payment failed ${payload.error.message}`);
  //     setProcessing(false);
  //   } else {
  //     setError(null);
  //     setProcessing(false);
  //     setSucceeded(true);
  //     checkedEndContracts.map(item => {
  //       const offerRef = database.ref(`OT/${item.OfferId}`);
  //       offerRef.update({
  //         funded: true,
  //         fundedTimestamp: (new Date()).toISOString(),
  //       });
  //     });
  //   }
  // };

  // Fro ACH Payment

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // return_url: 'http://localhost:3000/paymentstatus',
        return_url: 'https://subworknow-web.com/paymentstatus',
      },
    });


    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    // // Debit-Card Payment Form
    // <form id="payment-form" onSubmit={handleSubmit} className="mx-auto mt-10">
    //   <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
    //   <button className="pay-button"
    //     disabled={processing || disabled || succeeded}
    //     id="submit"
    //   >
    //     <span id="button-text">
    //       {processing ? (
    //         <div className="spinner" id="spinner"></div>
    //       ) : (
    //         "Pay now"
    //       )}
    //     </span>
    //   </button>
    //   {/* Show any error that happens when processing the payment */}
    //   {error && (
    //     <div className="card-error" role="alert">
    //       {error}
    //     </div>
    //   )}
    //   {/* Show a success message upon completion */}
    //   <p className={succeeded ? "result-message" : "result-message hidden1"}>
    //     Payment succeeded
    //   </p>
    // </form>

    // ACH Payment Form
    <form onSubmit={handleSubmit} id="payment-form" className="mx-auto mt-10">
      <PaymentElement />
      <button className="pay-button" disabled={!stripe}>Submit</button>
      {/* Show error message to your customers */}
      {errorMessage && <div className="card-error" role="alert">{errorMessage}</div>}
    </form>
  );
}

