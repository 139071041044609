import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../providers/Authentication";
import { analytics, database } from "../firebase";
import avatar from "../assets/image/avatar.png";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const OfferPage = () => {
  const {
    state: { job, bid },
  } = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);

  const [description, setDescription] = useState("");
  // eslint-disable-next-line
  const [indicator, setIndicator] = useState(false);
  const [longTitle, setLongTitle] = useState('General Contractor');
  const [shortTitle, setShortTitle] = useState('GC');
  const gEmail = bid ? bid.gEmail : "";
  const sEmail = bid ? bid.sEmail : "";
  const gLegal = bid ? bid.gLegal : "";
  const sLegal = bid ? bid.sLegal : "";
  const sPlayerId = bid ? bid.sPlayerId : "";
  const sUid = bid ? bid.sUid : "";

  useEffect(() => {
    analytics.logEvent('offer_page');
    if (currentUser.userType === 0) {
      setLongTitle("General Contractor");
      setShortTitle("GC");
    } else if (currentUser.userType === 2) {
      setLongTitle("Realtor");
      setShortTitle("R");
    }
  }, []);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "description") {
      setDescription(value);
    }
  };

  const onHire = async (e) => {
    e.preventDefault();
    try {
      setIndicator(true);
      const postKey = database.ref().child("OT").push().key;
      database
        .ref(`OT/${postKey}`)
        .set({
          BidId: bid.BidId,
          UserUid: currentUser.UserUid,
          Description: description,
          OfferId: postKey,
          SCUid: sUid,
          isReplied: false,
          Timestamp: (new Date()).toISOString(),
          JobId: bid.JobId,
        })
        .then((res) => {
          // toast('Offer sent successfully!');
          const msg = {
            personalizations: [
              {
                from: {
                  email: "rey@subworknow.com",
                  name: "SubWorkNow",
                },
                to: [
                  {
                    email: gEmail,
                    name: gLegal,
                  },
                  {
                    email: sEmail,
                    name: sLegal,
                  },
                ],
              },
            ],
            from: {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
            reply_to: {
              email: "rey@subworknow.com",
              name: "SubWorkNow",
            },
            subject: "Congratulations",
            content: [
              {
                type: "text/html",
                value: `<!doctype html>
              <html>
                <head>
                  <meta name="viewport" content="width=device-width" />
                  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                  <title>Congratulations</title>
                  <style>
                    body {
                      background-color: #f6f6f6;
                      font-family: sans-serif;
                      -webkit-font-smoothing: antialiased;
                      font-size: 14px;
                      line-height: 1.4;
                      margin: 0;
                      padding: 0;
                      -ms-text-size-adjust: 100%;
                      -webkit-text-size-adjust: 100%; 
                    }
              
                    table {
                      border-collapse: separate;
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                      width: 100%; }
                      table td {
                        font-family: sans-serif;
                        font-size: 14px;
                        vertical-align: top; 
                    }
              
                    /* -------------------------------------
                        BODY & CONTAINER
                    ------------------------------------- */
              
                    .body {
                      background-color: #f6f6f6;
                      width: 100%; 
                    }
              
                    /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
                    .container {
                      display: block;
                      margin: 0 auto !important;
                      /* makes it centered */
                      max-width: 580px;
                      padding: 10px;
                      width: 580px; 
                    }
              
                    /* This should also be a block element, so that it will fill 100% of the .container */
                    .content {
                      box-sizing: border-box;
                      display: block;
                      margin: 0 auto;
                      max-width: 580px;
                      padding: 10px; 
                    }
              
                    /* -------------------------------------
                        HEADER, FOOTER, MAIN
                    ------------------------------------- */
                    .main {
                      background: #ffffff;
                      border-radius: 3px;
                      width: 100%; 
                    }
              
                    .wrapper {
                      box-sizing: border-box;
                      padding: 20px; 
                    }
              
                    .content-block {
                      padding-bottom: 10px;
                      padding-top: 10px;
                    }
              
                    /* -------------------------------------
                        TYPOGRAPHY
                    ------------------------------------- */
                    h1,
                    h2,
                    h3,
                    h4 {
                      color: #000000;
                      font-family: sans-serif;
                      font-weight: 400;
                      line-height: 1.4;
                      margin: 0;
                      margin-bottom: 30px; 
                    }
              
                    h1 {
                      font-size: 35px;
                      font-weight: 300;
                      text-align: center;
                      text-transform: capitalize; 
                    }
              
                    p,
                    ul,
                    ol {
                      font-family: sans-serif;
                      font-size: 14px;
                      font-weight: normal;
                      margin: 0;
                      margin-bottom: 15px; 
                    }
                      p li,
                      ul li,
                      ol li {
                        list-style-position: inside;
                        margin-left: 5px; 
                    }
              
                    a {
                      color: #3498db;
                      text-decoration: underline; 
                    }
              
                    hr {
                      border: 0;
                      border-bottom: 1px solid #f6f6f6;
                      margin: 20px 0; 
                    }
              
                    /* -------------------------------------
                        RESPONSIVE AND MOBILE FRIENDLY STYLES
                    ------------------------------------- */
                    @media only screen and (max-width: 620px) {
                      table[class=body] h1 {
                        font-size: 28px !important;
                        margin-bottom: 10px !important; 
                      }
                      table[class=body] p,
                      table[class=body] ul,
                      table[class=body] ol,
                      table[class=body] td,
                      table[class=body] span,
                      table[class=body] a {
                        font-size: 16px !important; 
                      }
                      table[class=body] .wrapper,
                      table[class=body] .article {
                        padding: 10px !important; 
                      }
                      table[class=body] .content {
                        padding: 0 !important; 
                      }
                      table[class=body] .container {
                        padding: 0 !important;
                        width: 100% !important; 
                      }
                      table[class=body] .main {
                        border-left-width: 0 !important;
                        border-radius: 0 !important;
                        border-right-width: 0 !important; 
                      }
                      table[class=body] .btn table {
                        width: 100% !important; 
                      }
                      table[class=body] .btn a {
                        width: 100% !important; 
                      }
                      table[class=body] .img-responsive {
                        height: auto !important;
                        max-width: 100% !important;
                        width: auto !important; 
                      }
                    }
              
                  </style>
                </head>
                <body class="">
                  <span class="preheader"></span>
                  <div style="display: flex; align-items: center;">
                    <img src="https://i.ibb.co/b5WpDdv/logo.png" alt="logo" border="0">
                    <p style="font-weight: bold; margin-left: 8px; color: black;">SubWorkNow</p>
                  </div>
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
                    <tr>
                      <td>&nbsp;</td>
                      <td class="container">
                        <div class="content">
              
                          <!-- START CENTERED WHITE CONTAINER -->
                          <table role="presentation" class="main">
              
                            <!-- START MAIN CONTENT AREA -->
                            <tr>
                              <td class="wrapper">
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                  <tr>
                                    <td>
                                      <p>Congratulations,</p>
                                      <br/>
                                      <p>The associated “Job” information is as follows:</p>
                                      <ul>
                                        <li>Services Needed: ${job.ServicesNeeded}</li>
                                        <li>Location: ${job.Location}</li>
                                        <li>Zip Code: ${job.ZipCode}</li>
                                        <li>Description: ${job.Description}</li>
                                      </ul> 
                                      <br/>
                                      <p>The “${longTitle}” information is as follows:</p>
                                      <ul>
                                        <li>${shortTitle} Legal Entity Name: ${bid.gLegal}</li>
                                        <li>${shortTitle} Point of Contract: ${bid.gPoint}</li>
                                        <li>${shortTitle} Primary Email: ${bid.gEmail}</li>
                                        <li>${shortTitle} Phone: ${bid.gPhone}</li>
                                      </ul> 
                                      <br/>
                                      <p>The “Sub-Contractor” information is as follows:</p>
                                      <ul>
                                        <li>SC License Number: ${bid.sLicense}</li>
                                        <li>SC Legal Entity Name: ${bid.sLegal}</li>
                                        <li>SC Point of Contact: ${bid.sPoint}</li>
                                        <li>SC Primary Email: ${bid.sEmail}</li>
                                        <li>SC Phone: ${bid.sPhone}</li>
                                        <li>SC Website: ${bid.sWebsite}</li>
                                      </ul> 
                                      <br/>
                                      <p>This information is being sent as a courtesy to our SubWorkNow.com members.</p>
                                      <br/>
                                      <p>Also please feel free to use the SubWorkNow.com App messaging/chat system to further coordination between both of you.</p>
                                      <br/>
                                      <p>Again, please do not hesitate to reach out to us with any questions or for assistance in the “Contact Us” section.</p>
                                      <br/>
                                      <br/>
                                      <p>SubWorkNow.com</p>
                                      <p>
                                        <a>rey@subworknow.com</a>
                                      </p>
                                      <p>1-844-SUBWORK1 (1-844-782-6691)</p>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
              
                          <!-- END MAIN CONTENT AREA -->
                          </table>
                          <!-- END CENTERED WHITE CONTAINER -->
              
                        </div>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </table>
                </body>
              </html>
              `,
              },
            ],
          };

          axios
            .post(
              "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
              msg,
              { headers: { "Content-Type": "application/json" } }
            )
            .then(function (response) {
            })
            .catch(function (error) {
            });

          const pushContent = {
            app_id: "f6696b79-ba9e-4042-a68e-d3c38bb2a741", // Live
            // app_id: 'e048cef2-fc4d-4cfc-aea0-c3ad1b5ee3eb', // Localhost
            include_player_ids: [sPlayerId],
            contents: {
              en: `Hi, ${sLegal}. ${gLegal} has just accepted your bid or made a proposal.`,
            },
          };
          axios
            .post("https://onesignal.com/api/v1/notifications", pushContent)
            .then(function (response) {
            })
            .catch(function (error) {
            });
          history.push("/offersent");
        })
        .catch((err) => {
          setIndicator(false);
        });
    } catch (err) {
      setIndicator(false);
    }
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center p-4">
        <div className="w-96 px-4 py-2 my-2 flex items-center">
          <img
            src={bid.icon ? bid.icon : avatar}
            alt="..."
            className="w-16 h-16 rounded-full"
          />
          <div className="ml-4">
            <p>{job.ServicesNeeded}</p>
            <p>{bid.Proposal}</p>
            <p>Price Range: ${bid.Budget}</p>
            <p>Timeline: {bid.Timeline}</p>
          </div>
        </div>
        Leave the comment:
        <textarea
          className="mt-2 p-2 w-80 md:w-96 h-36 border border-black-400 mb-4"
          name="description"
          value={description}
          placeholder=""
          id="description"
          onChange={(event) => onChangeHandler(event)}
        />
        <button
          className={`w-72 ${
            !description ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={(event) => onHire(event)}
          disabled={!description}
        >
          Accept Bid or Make Proposal
        </button>
        <button
          className='w-32 bg-primary text-white py-2 my-3 rounded-full focus:outline-none'
          onClick={() => history.goBack()}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default OfferPage;
