import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import axios from "axios";
import { ThreeBounce } from "better-react-spinkit";
import { database } from "../firebase";
import { useHistory, useLocation } from "react-router-dom";

const EscrowTransferPage = () => {
  const {
    state: { accountId },
  } = useLocation();

  const { currentUser } = useContext(AuthContext);
  const [indicator, setIndicator] = useState(false);
  const [endedContracts, setEndedContracts] = useState([]);
  const [checkedEndContracts, setCheckedEndContracts] = useState([]);
  const [resultSentence, setResultSentence] = useState("");

  useEffect(() => {
    
    async function fetchData() {
      setIndicator(true);

      let tempContracts = []; 
      let updatedContracts = [];

      await database.ref("OT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            if (
              child.val().isEnded &&
              child.val().SCUid === currentUser.UserUid
            ) 
              tempContracts.push(child.val());
          });
        }
      });
      let item = {};
      for (item of tempContracts) {
        let tempUserId = item.UserUid;
        let snapshot1 = null;
        const snap = await database.ref(`GCT/${tempUserId}`).once("value");
        if(!!snap.val()) {
          snapshot1 = snap;
        } else {
          snapshot1 = await database.ref(`RT/${tempUserId}`).once("value");
        }
        const email = snapshot1.val().Email;
        const legal = snapshot1.val().LegalEntity;
        let tempBidId = item.BidId;
        const snapshot2 = await database.ref(`BT/${tempBidId}`).once("value");
        const budget = snapshot2.val().Budget;
        const tempJobId = snapshot2.val().JobId;
        const snapshot3 = await database.ref(`JT/${tempJobId}`).once("value");
        const servicesNeeded = snapshot3.val().ServicesNeeded;
        const newItem = {
          ...item,
          email,
          legal,
          budget,
          servicesNeeded,
          checkVal: false,
        };
        updatedContracts.push(newItem);
      }
      setEndedContracts(updatedContracts);

      setIndicator(false);
    }

    fetchData();
  }, []);

  const onCheckReleaseHandler = (position) => {
    const updatedEndedContracts = endedContracts.map((item, index) =>
      index === position ? {...item, checkVal: !item.checkVal} : {...item, checkVal: item.checkVal}
    );
    setEndedContracts(updatedEndedContracts);
    let temp = [];
    temp = updatedEndedContracts.filter(item => item.checkVal === true);
    setCheckedEndContracts(temp);
  }

  const onClickReleaseHandler = (event) => {
    event.preventDefault();
    setIndicator(true);
    console.log("checked end contracts=>", checkedEndContracts);
    let totalAmount = 0;
    let item = {};
    for (item of checkedEndContracts) {
      totalAmount += item.budget * 1;
    }
    console.log("total amount=>", totalAmount);
    console.log("account id=>", accountId);
    axios
      .post(
        "https://us-central1-subworknow.cloudfunctions.net/transferStripeAccount",
        // "http://localhost:5001/subworknow/us-central1/transferStripeAccount",
        {
          accountId: accountId,
          totalAmount: totalAmount,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function (response) {
        setIndicator(false);
        const data = response.data;
        console.log("transfer response data=>", data, response);
        const status = response.status;
        console.log("message==>", data.raw.message);
        if(status === 200) {  
          if(data.statusCode === 400) {
            if(!!data.raw.message) {
              setResultSentence(data.raw.message);
            } else {
              setResultSentence("Payment Transfer Failed!");
            }
          } else {
            setResultSentence("Payment Transfer Success!");
          }          
        }
      })
      .catch(function (error) {
        console.log("transfer error=>", error);
        setIndicator(false);
      });
  }

  return (
    <div className="w-full">
      <Navbar />
      {indicator &&
        <div className="flex justify-center items-center flex-col my-4">
          <ThreeBounce size={24} color="#03a9f4" />
          Please wait...
        </div>
      }
      <div className="flex justify-center items-center py-4">
        Escrow Process
      </div>
      { endedContracts.length === 0 ? 
        <p className="flex justify-center mx-auto">No ended contracts yet.</p>
        : 
        <div>
          {endedContracts.map((contract, i) => 
            <div key={i} className="flex justify-between w-full max-w-xl items-center mx-auto border rounded p-4 my-4">
              <div>
                <div>Job: {contract.servicesNeeded}</div>
                <div>LegalEntity: {contract.legal}</div>
                <div>Budget: ${contract.budget}</div>
                <div>Funded: {!!contract.funded ? "Yes" : "No"}</div>
                <div>Ended Date: {contract.Timestamp}</div>
              </div>
              <label className="mt-2">
                <input
                  type="checkbox"
                  name={`release-${i}`}
                  checked={contract.checkVal}
                  onChange={() => onCheckReleaseHandler(i)}
                  disabled={!!contract.funded ? false : true}
                />
                &nbsp;&nbsp;&nbsp;Release
              </label>
            </div>
          )}
          <button
            className={`${
              checkedEndContracts.length > 0 ? "bg-primary" : "bg-gray-500"
            } flex justify-center mx-auto w-4/5 md:w-1/5 mt-6 py-2 text-white rounded`}
            onClick={(event) => {
              onClickReleaseHandler(event);
            }}
            disabled={checkedEndContracts.length === 0}
          >
            Release
          </button>
          { !!resultSentence &&
            <div className="mt-20 mx-10 text-xl text-center text-red border rounded p-2">
              {resultSentence}
            </div>
          }
        </div>
      }
    </div>
  );
};

export default EscrowTransferPage;