import React, { useContext, useState } from "react";
import { AuthContext } from "../providers/Authentication";
import { auth, database } from "../firebase";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../assets/image/logo.png";

export default function Navbar({ fixed, getOfferData }) {
  const history = useHistory();
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuIsOpen1, setMenuIsOpen1] = useState(false);
  const [offers, setOffers] = useState([]);

  const onOptionClicked = () => {
    setMenuIsOpen(false);
  };

  const handleMouseOver = () => {
    setMenuIsOpen(true);
  };

  const handleMouseLeave = () => {
    setMenuIsOpen(false);
  };

  const onOptionClicked1 = (offer) => {
    setMenuIsOpen1(false);
    if (location.pathname === "/viewoffer") {
      getOfferData(offer);
    } else {
      history.push({
        pathname: "viewoffer",
        state: { offer },
      });
    }
  };

  const handleClicked = async () => {
    if (!menuIsOpen1) {
      setMenuIsOpen1(true);
      let tempOffers = [];
      let updateOffers = [];
      await database.ref("OT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            if (
              child.val().SCUid === currentUser.UserUid &&
              child.val().isReplied === false
            )
              tempOffers.push(child.val());
          });
        }
      });

      let item = {};
      for (item of tempOffers) {
        const gUserId = item.UserUid;
        let snapshot1 = null;
        const snap = await database.ref(`GCT/${gUserId}`).once("value");
        if (!!snap.val()) {
          snapshot1 = snap;
        } else {
          snapshot1 = await database.ref(`RT/${gUserId}`).once("value");
        }
        const gLegal = snapshot1.val().LegalEntity;
        const gPlayerId = snapshot1.val().PlayerId;
        const gEmail = snapshot1.val().Email;
        const gIcon = snapshot1.val().IconUpload;
        const newItem = { ...item, gLegal, gPlayerId, gEmail, gIcon };
        updateOffers.push(newItem);
      }
      setOffers(updateOffers);
    } else {
      setMenuIsOpen1(false);
    }
  };

  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-gray-800">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex flex-start lg:w-auto lg:static lg:block lg:justify-start">
            <button
              className="text-white cursor-pointer text-xl leading-none px-2 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fa fa-bars"></i>
            </button>
            <a
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white flex items-center"
              href="/"
            >
              SubWorkNow
              <img
                src={logo}
                alt="..."
                className="shadow align-middle border-none ml-2"
              />
            </a>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto lg:items-center lg:w-min">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/"
                >
                  <i className="fa fa-home text-lg leading-lg text-white opacity-75"></i>
                  <span className="ml-2">Home</span>
                </a>
              </li>
              <li className="nav-item">
                {currentUser && (
                  <div
                    className="relative cursor-pointer"
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                      <i className="fa fa-user text-lg leading-lg text-white opacity-75"></i>
                      <span className="ml-2">Account Management</span>
                    </div>
                    {menuIsOpen && (
                      <div className="absolute border border-solid border-black z-50">
                        <ul className="pl-0 bg-white text-black w-64">
                          <div className="w-full" onClick={onOptionClicked}>
                            <li className="nav-item">
                              <a
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-50"
                                href="/assistant"
                              >
                                <i className="fa fa-question-circle text-lg leading-lg opacity-75"></i>
                                <span className="ml-2">
                                  SWN Process & Assistance/Help
                                </span>
                              </a>
                            </li>
                          </div>
                        </ul>
                        <ul className="pl-0 bg-white text-black w-64">
                          <div className="w-full" onClick={onOptionClicked}>
                            <li className="nav-item">
                              <a
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-50"
                                href="/terms"
                              >
                                <i className="fa fa-legal text-lg leading-lg opacity-75"></i>
                                <span className="ml-2">Terms & Policies</span>
                              </a>
                            </li>
                          </div>
                        </ul>
                        <ul className="pl-0 bg-white text-black w-64">
                          <div className="w-full" onClick={onOptionClicked}>
                            <li className="nav-item">
                              <a
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-50"
                                href="/contactus"
                              >
                                <i className="fa fa-phone text-lg leading-lg opacity-75"></i>
                                <span className="ml-2">Contact Us</span>
                              </a>
                            </li>
                          </div>
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </li>
              <li className="nav-item">
                {currentUser && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/profile"
                  >
                    <i className="fa fa-user-circle text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">Profile & SWN&nbsp;Coins</span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {currentUser && (currentUser.userType === 0 || currentUser.userType === 2) && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/jobs"
                  >
                    <i className="fa fa-briefcase text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">Post&nbsp;Jobs</span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {currentUser && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/myjobs"
                  >
                    <i className="fa fa-briefcase text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">
                      {(currentUser.userType === 0 || currentUser.userType === 2)
                        ? <div>My&nbsp;Jobs</div>
                        : <div>Available&nbsp;Jobs</div>}
                    </span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {currentUser && currentUser.userType === 1 && (
                  <div
                    className="relative cursor-pointer"
                    onClick={handleClicked}
                  >
                    <div className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                      <i className="fa fa-bell text-lg leading-lg text-white opacity-75"></i>
                      <span className="ml-2">Offers</span>
                      {offers.length > 0 && (
                        <div className="w-2 h-2 rounded-full bg-yellow-500 absolute left-5 top-2"></div>
                      )}
                    </div>
                    {menuIsOpen1 && (
                      <div className="absolute border border-solid border-black z-50">
                        {offers.length === 0 && (
                          <ul className="pl-4 py-2 bg-white text-black w-60 text-xs">
                            Any offers will appear momentarily.
                          </ul>
                        )}
                        {offers.length > 0 &&
                          offers.map((offer) => (
                            <ul
                              className="pl-0 bg-white text-black w-60"
                              key={offer.OfferId}
                            >
                              <div
                                className="w-full"
                                onClick={() => onOptionClicked1(offer)}
                              >
                                <li className="nav-item">
                                  <a className="px-3 py-2 flex items-center text-xs font-bold leading-snug hover:opacity-50">
                                    <span className="ml-2">{`${offer.gLegal} sent you the offer.`}</span>
                                  </a>
                                </li>
                              </div>
                            </ul>
                          ))}
                      </div>
                    )}
                  </div>
                )}
              </li>
              <li className="nav-item">
                {currentUser && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/contracts"
                  >
                    <i className="fa fa-id-card text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">Contracts</span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {currentUser && currentUser.userType === 1 && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/w9"
                  >
                    <i className="fa fa-balance-scale text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">W-9 & Contractor Agreement</span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {currentUser && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/escrow"
                  >
                    <i className="fa fa-money text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">Escrow</span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {currentUser && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/messaging"
                  >
                    <i className="fa fa-comments text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">Messaging</span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {currentUser && (currentUser.userType === 0 || currentUser.userType === 2) && (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/forum"
                  >
                    <i className="fa fa-comments text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">Discussion Forum</span>
                  </a>
                )}
              </li>
              <li className="nav-item">
                {!!currentUser ? (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="/"
                    onClick={() => {
                      auth.signOut();
                    }}
                  >
                    <i className="fa fa-sign-out text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">LogOut</span>
                  </a>
                ) : (
                  <a
                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                    href="signin"
                  >
                    <i className="fa fa-sign-in text-lg leading-lg text-white opacity-75"></i>
                    <span className="ml-2">LogIn&nbsp;/&nbsp;Register</span>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
