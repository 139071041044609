import React from "react";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";

const CongratulationPage = () => {
  const history = useHistory();

  const onClose = (e) => {
    e.preventDefault();
    history.go(-2);
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center p-4 text-2xl font-bold text-center">
        <p className="mt-4">Congratulations,</p>
        <p className="mt-8">Your bid has been submitted.</p>
        <p>
          If accepted you will receive a confirmation email with contact
          information.
        </p>
        <button
          className={`w-48 bg-primary text-white py-2 mt-8 rounded-full focus:outline-none`}
          onClick={(event) => onClose(event)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CongratulationPage;
