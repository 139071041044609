import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";
import { analytics } from "../firebase";
import escrowSymbol from "../assets/image/escrow.jpg";
import gcAndSc from "../assets/image/gc_and_sc.jpg";
import jobCompleted from "../assets/image/job_completed.jpg";
import postPayment from "../assets/image/post_payment.jpg";
import rightArrow from "../assets/image/right_arrow.png";

const DATA = [
  {
    id: 1,
    title: "Cabinet & Shelf Installation",
  },
  {
    id: 2,
    title: "Cleaning Prep",
  },
  {
    id: 3,
    title: "Door & Garage Installation",
  },
  {
    id: 4,
    title: "Drywall & Plastering",
  },
  {
    id: 5,
    title: "Electrical",
  },
  {
    id: 6,
    title: "Excavation & Leveling",
  },
  {
    id: 7,
    title: "Fencing",
  },
  {
    id: 8,
    title: "Flooring",
  },
  {
    id: 9,
    title: "Foundation, Cement & Concrete",
  },
  {
    id: 10,
    title: "Framing",
  },
  {
    id: 11,
    title: "Glass & Mirror",
  },
  {
    id: 12,
    title: "HVAC",
  },
  {
    id: 13,
    title: "Insulation",
  },
  {
    id: 14,
    title: "Kitchen Appliance Installation",
  },
  {
    id: 15,
    title: "Kitchen & Bathroom Counter Installation",
  },
  {
    id: 16,
    title: "Lawn & Garden",
  },
  {
    id: 17,
    title: "Masonry",
  },
  {
    id: 18,
    title: "Painting",
  },
  {
    id: 19,
    title: "Plumbing",
  },
  {
    id: 20,
    title: "Post construction Cleaning",
  },
  {
    id: 21,
    title: "Roofing",
  },
  {
    id: 22,
    title: "Siding & Stucco",
  },
  {
    id: 23,
    title: "Solar Installation",
  },
];

const JobsPage = () => {
  const history = useHistory();
  const [selectedSub, setSelectedSub] = useState(DATA[0].title);

  useEffect(() => {
    analytics.logEvent("post_a_job");
  }, []);

  const actionOnRow = (item) => {
    setSelectedSub(item.title);
  };

  const onPress = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/location",
      state: { neededService: selectedSub },
    });
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        <div className="flex justify-center items-center text-center text-sm my-8 flex-wrap">
          <div className="max-w-160">
            GC Posts "Job"
            <img src={jobCompleted} alt="..." className="w-16 h-auto m-auto" />
          </div>
          <img src={rightArrow} alt="..." className="w-16 h-8" />
          <div className="max-w-160">
            SC Places "Bid/Proposal" on Posted "Job"
            <img src={jobCompleted} alt="..." className="w-16 h-auto m-auto" />
          </div>
          <img src={rightArrow} alt="..." className="w-16 h-8" />
          <div className="max-w-160">
            GC Selects a "Bid/Proposal"
            <img src={gcAndSc} alt="..." className="w-16 h-auto m-auto" />
          </div>
          <img src={rightArrow} alt="..." className="w-16 h-8" />
          <div className="max-w-160">
            SC Receives an "Offer" from GC
            <img src={gcAndSc} alt="..." className="w-16 h-auto m-auto" />
          </div>
          <img src={rightArrow} alt="..." className="w-16 h-8" />
          <div className="max-w-160">
            SC Accepts "Offer" Thus Making a "Contract"
            <img src={gcAndSc} alt="..." className="w-16 h-auto m-auto" />
          </div>
          <img src={rightArrow} alt="..." className="w-16 h-8" />
          <div className="max-w-160">
            SC Completes "Job" and "Notifies" GC
            <img src={postPayment} alt="..." className="w-16 h-auto m-auto" />
          </div>
          <img src={rightArrow} alt="..." className="w-16 h-8" />
          <div className="max-w-160">
            GC & SC Payment via "Escrow" (Coming Soon!)
            <img src={escrowSymbol} alt="..." className="w-16 h-auto m-auto" />
          </div>
        </div>
        Please choose the service needed.
        <ul className="mt-4 border py-3 px-6">
          {DATA.map((service) => (
            <li
              key={service.id}
              className={`text-center cursor-pointer py-1
                ${
                  selectedSub === service.title
                    ? "text-primary"
                    : "text-black-500"
                }
                ${selectedSub === service.title ? "font-bold" : "font-normal"}
                `}
              onClick={() => actionOnRow(service)}
            >
              {service.title}
            </li>
          ))}
        </ul>
        <button
          className="w-48 bg-primary text-white py-2 my-3 rounded-full focus:outline-none"
          onClick={(event) => {
            onPress(event);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default JobsPage;
