import React, { useState, useEffect, useContext, useRef } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import { analytics, database, storage } from "../firebase";
import { ThreeBounce } from "better-react-spinkit";
import avatar from "../assets/image/avatar.png";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import axios from "axios";
import { FadingCircle } from "better-react-spinkit";
import Tooltip from "../components/Tooltip";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ContractsPage = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [contracts, setContracts] = useState([]);
  const [indicator, setIndicator] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen1, setIsOpen1] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [indicator1, setIndicator1] = useState(false);
  const [indicator2, setIndicator2] = useState(false);
  const [indicator3, setIndicator3] = useState(false);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  useEffect(() => {
    async function fetchData() {
      if (currentUser && currentUser.userType === 1) {
        setIndicator(true);
        let tempContracts = [];
        let updatedContracts = [];
        await database.ref("OT").once("value", (snapshot) => {
          if (!!snapshot.val()) {
            snapshot.forEach((child) => {
              if (
                child.val().isAccepted &&
                child.val().SCUid === currentUser.UserUid
              )
                tempContracts.push(child.val());
            });
          }
        });
        let item = {};
        for (item of tempContracts) {
          let tempUserId = item.UserUid;
          let snapshot1 = null;
          const snap = await database.ref(`GCT/${tempUserId}`).once("value");
          if (!!snap.val()) {
            snapshot1 = snap;
          } else {
            snapshot1 = await database.ref(`RT/${tempUserId}`).once("value");
          }
          const icon = snapshot1.val().IconUpload;
          const email = snapshot1.val().Email;
          const legal = snapshot1.val().LegalEntity;
          const phone = snapshot1.val().Phone;
          const pointOfContact = snapshot1.val().PointOfContact;
          let tempBidId = item.BidId;
          const snapshot2 = await database.ref(`BT/${tempBidId}`).once("value");
          const budget = snapshot2.val().Budget;
          const timeline = snapshot2.val().Timeline;
          const proposal = snapshot2.val().Proposal;
          const tempJobId = snapshot2.val().JobId;
          const snapshot3 = await database.ref(`JT/${tempJobId}`).once("value");
          const location = snapshot3.val().Location;
          const description = snapshot3.val().Description;
          const servicesNeeded = snapshot3.val().ServicesNeeded;
          const newItem = {
            ...item,
            icon,
            email,
            legal,
            budget,
            timeline,
            location,
            description,
            servicesNeeded,
            proposal,
            phone,
            pointOfContact,
          };
          updatedContracts.push(newItem);
        }
        setContracts(updatedContracts);
        setIndicator(false);
      } else if (
        currentUser &&
        (currentUser.userType === 0 || currentUser.userType === 2)
      ) {
        setIndicator(true);

        let tempContracts = [];
        let updatedContracts = [];

        await database.ref("OT").once("value", (snapshot) => {
          if (!!snapshot.val()) {
            snapshot.forEach((child) => {
              if (
                child.val().UserUid === currentUser.UserUid &&
                child.val().isAccepted
              ) {
                tempContracts.push(child.val());
              }
            });
          }
        });

        let item = {};
        for (item of tempContracts) {
          let tempUserId = item.SCUid;
          const snapshot1 = await database
            .ref(`SCT/${tempUserId}`)
            .once("value");
          const icon = snapshot1.val().IconUpload;
          const email = snapshot1.val().Email;
          const legal = snapshot1.val().LegalEntityName;
          const pointOfContact = snapshot1.val().PointOfContact;
          const phone = snapshot1.val().Phone;
          let tempBidId = item.BidId;
          const snapshot2 = await database.ref(`BT/${tempBidId}`).once("value");
          const budget = snapshot2.val().Budget;
          const timeline = snapshot2.val().Timeline;
          const proposal = snapshot2.val().Proposal;
          const tempJobId = snapshot2.val().JobId;
          const snapshot3 = await database.ref(`JT/${tempJobId}`).once("value");
          const location = snapshot3.val().Location;
          const description = snapshot3.val().Description;
          const servicesNeeded = snapshot3.val().ServicesNeeded;
          const newItem = {
            ...item,
            icon,
            email,
            legal,
            budget,
            timeline,
            location,
            description,
            servicesNeeded,
            proposal,
            pointOfContact,
            phone,
          };
          updatedContracts.push(newItem);
        }
        setContracts(updatedContracts);
        setIndicator(false);
      }
    }

    analytics.logEvent("contracts_page");
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleUpload1 = () => {
    const fileInputElement = fileInputRef1.current;
    fileInputElement.click();
  };

  const handleUpload2 = () => {
    const fileInputElement = fileInputRef2.current;
    fileInputElement.click();
  };

  const handleUpload3 = () => {
    const fileInputElement = fileInputRef3.current;
    fileInputElement.click();
  };

  const handleChange = async (e, number) => {
    if (e.currentTarget.files !== null) {
      if (number === 1) {
        setIndicator1(true);
      } else if (number === 2) {
        setIndicator2(true);
      } else if (number === 3) {
        setIndicator3(true);
      }

      const fileUploaded = e.currentTarget.files[0];
      const fileType = fileUploaded.type || "type";
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate() +
        "-" +
        today.getTime();

      await storage
        .ref("SCT")
        .child(`${currentUser.UserUid}/${date}/${fileType}-file${number}`)
        .put(fileUploaded);
      const fileUrl = await storage
        .ref("SCT")
        .child(`${currentUser.UserUid}/${date}/${fileType}-file${number}`)
        .getDownloadURL();

      if (number === 1) {
        setFile1(fileUrl);
        setIndicator1(false);
      } else if (number === 2) {
        setFile2(fileUrl);
        setIndicator2(false);
      } else if (number === 3) {
        setFile3(fileUrl);
        setIndicator3(false);
      }
      toast("File Saved!");
    }
  };

  const onPressEnd = (e, contract) => {
    e.preventDefault();
    setSelectedContract(contract);
    setIsOpen(true);
  };

  const onPressCompletion = async (e, contract) => {
    setIsOpen1(true);
    setSelectedContract(contract);
  };

  const onCloseModal1 = () => {
    setIsOpen1(false);
  };

  const onFileUploadOK = async () => {
    const offerRef = database.ref(`OT/${selectedContract.OfferId}`);
    await offerRef.update({
      isCompleted: true,
    });

    const snapshot = await database
      .ref(`GCT/${selectedContract.UserUid}`)
      .once("value");
    const gLegal = snapshot.val().LegalEntity;
    const gEmail = snapshot.val().Email;
    const msg = {
      personalizations: [
        {
          from: {
            email: "rey@subworknow.com",
            name: "SubWorkNow",
          },
          to: [
            {
              email: gEmail,
              name: gLegal,
            },
          ],
        },
      ],
      from: {
        email: "rey@subworknow.com",
        name: "SubWorkNow",
      },
      reply_to: {
        email: "rey@subworknow.com",
        name: "SubWorkNow",
      },
      subject: "You have a new message from a SC",
      content: [
        {
          type: "text/html",
          value: `<!doctype html>
          <html>
            <body>
              <p>Hi ${gLegal}, SC has just completed the requested job. Please review the provided service and pay and/or release the associated funds within 7 days. Funds held in escrow will be released automatically in 7 days if no action is taken.</p>
              <p>Attachments:</p>
              <p>${file1}</p>
              <p>${file2}</p>
              <p>${file3}</p>
            </body>
          </html>
        `,
        },
      ],
    };

    axios
      .post(
        "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
        msg,
        { headers: { "Content-Type": "application/json" } }
      )
      .then(function (response) {
        setFile1("");
        setFile2("");
        setFile3("");
        toast("GC will be notified the job completion via email.");
      })
      .catch(function (error) {});

    setIsOpen1(false);
  };

  const onEndContract = async () => {
    const offerRef = database.ref(`OT/${selectedContract.OfferId}`);
    await offerRef.update({
      isEnded: true,
      endedTimestamp: (new Date()).toISOString(),
    });
    toast("This contract has been ended.");
    setTimeout(() => history.push("/"), 2000);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="w-full">
      <Navbar />
      {indicator ? (
        <div className="flex justify-center items-center flex-col my-80">
          <ThreeBounce size={24} color="#03a9f4" />
          Please wait...
        </div>
      ) : (
        <div className="flex flex-col items-center py-4">
          {contracts.length === 0 ? (
            <p>No contracts yet.</p>
          ) : (
            contracts.map((contract) => (
              <div
                className="w-80 md:w-96 border px-4 py-2 my-2 relative"
                key={contract.OfferId}
              >
                <div className="flex items-center mb-4">
                  <img
                    src={contract.icon || avatar}
                    alt="..."
                    className="w-16 h-16 rounded-full"
                  />
                  <p className="ml-4 flex-1 font-bold">{contract.legal}</p>
                </div>
                <p>Service: {contract.servicesNeeded}</p>
                <p>
                  {currentUser.userType === 0
                    ? `Proposal: ${contract.proposal}`
                    : `Description: ${contract.description}`}
                </p>
                <p>Location: {contract.location}</p>
                <p>Budget: {contract.budget}</p>
                <p>Timeline: {contract.timeline}</p>
                {!contract.isEnded && currentUser.userType === 1 && (
                  <div className="flex justify-center">
                    <button
                      className={`w-64 bg-primary text-white py-2 my-2 rounded-full focus:outline-none`}
                      onClick={(event) => onPressCompletion(event, contract)}
                    >
                      Notify GC of Job Completion
                    </button>
                  </div>
                )}
                <div className="flex items-center justify-evenly">
                  <button
                    className={`w-32  ${
                      !contract.isEnded ? "bg-primary" : "bg-gray-500"
                    } text-white py-2 my-2 rounded-full focus:outline-none`}
                    onClick={(event) => onPressEnd(event, contract)}
                    disabled={contract.isEnded}
                  >
                    {contract.isEnded ? "Ended" : "End Contract"}
                  </button>
                </div>
                <div className="absolute top-2 right-2 text-white bg-primary py-1 px-3 rounded-full text-sm cursor-pointer">
                  <Tooltip
                    text={`Point Of Contact: ${
                      contract.pointOfContact || "N/A"
                    } Phone: ${contract.phone || "N/A"}`}
                    boxStyle={{ width: 180, left: -60 }}
                  >
                    {currentUser.userType === 1 ? 'Contact GC' : 'Contact SC'}
                  </Tooltip>
                </div>
              </div>
            ))
          )}
        </div>
      )}
      <ToastContainer />
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">Do you really want to end this contract?</p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onEndContract}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <Modal isOpen={modalIsOpen1} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">Please submit the photos or videos here.</p>
        <div className="flex">
          <input
            type="text"
            className="my-1 p-1 w-full border border-black-400"
            name="file1"
            value={file1}
            placeholder=""
            disabled
          />
          <input
            type="file"
            ref={fileInputRef1}
            onChange={(e) => handleChange(e, 1)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload1(event);
            }}
          >
            {indicator1 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>
        <div className="flex">
          <input
            type="text"
            className="my-1 p-1 w-full border border-black-400"
            name="file2"
            value={file2}
            placeholder=""
            disabled
          />
          <input
            type="file"
            ref={fileInputRef2}
            onChange={(e) => handleChange(e, 2)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload2(event);
            }}
          >
            {indicator2 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>
        <div className="flex">
          <input
            type="text"
            className="my-1 p-1 w-full border border-black-400"
            name="file3"
            value={file3}
            placeholder=""
            disabled
          />
          <input
            type="file"
            ref={fileInputRef3}
            onChange={(e) => handleChange(e, 3)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload3(event);
            }}
          >
            {indicator3 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={onFileUploadOK}
          >
            OK
          </button>
          <button
            className={`w-24 bg-primary text-white py-1 my-3 ml-3 rounded-full focus:outline-none`}
            onClick={onCloseModal1}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ContractsPage;
