import React from "react";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";

const PostSuccessPage = () => {
  const history = useHistory();

  const onClose = (e) => {
    e.preventDefault();
    history.push("/");
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4 text-2xl font-bold px-6 text-center">
        <p className="mt-4">Congratulations,</p>
        <p className="mt-8">Your Job has been successfully submitted.</p>
        <p>Please check your email for additional information.</p>
        <button
          className={`w-48 bg-primary text-white py-2 mt-8 rounded-full focus:outline-none`}
          onClick={(event) => onClose(event)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PostSuccessPage;
