import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import HomePage from "../pages/HomePage";
import PasswordReset from "../pages/PasswordReset";
import ProfilePage from "../pages/ProfilePage";
import AMPage from "../pages/AMPage";
import JobsPage from "../pages/JobsPage";
import MyJobsPage from "../pages/MyJobsPage";
import W9Page from "../pages/W9Page";
import MessagingPage from "../pages/MessagingPage";
import DiscussionForumPage from "../pages/DiscussionForumPage";
import ContactUsPage from "../pages/ContactUsPage";
import AssistantPage from "../pages/AssistantPage";
import TermsPage from "../pages/TermsPage";
import LocationPage from "../pages/LocationPage";
import FilesPage from "../pages/FilesPage";
import BudgetPage from "../pages/BudgetPage";
// import JobDetailPage from "../pages/JobDetailPage";
import BidPage from "../pages/BidPage";
import CongratulationPage from "../pages/CongratulationPage";
import ModifyJobPage from "../pages/ModifyJobPage";
import CurrentBidsPage from "../pages/CurrentBidsPage";
import ViewSCPage from "../pages/ViewSCPage";
import OfferPage from "../pages/OfferPage";
import PostSuccessPage from "../pages/PostSuccessPage";
import OfferSentPage from "../pages/OfferSentPage";
import ViewOfferPage from "../pages/ViewOfferPage";
import OfferSuccessPage from "../pages/OfferSuccess";
import AddRoomPage from "../pages/AddRoomPage";
import RoomPage from "../pages/RoomPage";
import W9SuccessPage from "../pages/W9SuccessPage";
import ViewImagePage from "../pages/ViewImagePage";
import ContractsPage from "../pages/ContractsPage";
import EscrowPage from "../pages/EscrowPage";
import AddGroupRoomPage from "../pages/AddGroupRoomPage";
import GroupRoomPage from "../pages/GroupRoomPage";
import PayNowPage from "../pages/PayNowPage";
import EmailNotifyPage from "../pages/EmailNotifyPage";
import OAuthRedirect from "../pages/OAuthRedirect";
import EscrowTransferPage from "../pages/EscrowTransferPage";
import EscrowChargePage from "../pages/EscrowChargePage";
import LandingPage from "../pages/LandingPage";
import LandingSuccessPage from "../pages/LandingSuccessPage";
import PaymentStatusPage from "../pages/PaymentStatusPage";

function PublicRoute() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/passwordreset" component={PasswordReset} />
        <Route exact path="/assistant" component={AssistantPage} />
        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/contactus" component={ContactUsPage} />
        <Route exact path="/emailnotify/:id" component={EmailNotifyPage} />
        <Route exact path="/landingpage" component={LandingPage} />
        <Route exact path="/landingsuccess" component={LandingSuccessPage} />
        <PrivateRoute exact path="/profile" component={ProfilePage} />
        <PrivateRoute exact path="/accountmanagement" component={AMPage} />
        <PrivateRoute exact path="/jobs" component={JobsPage} />
        <PrivateRoute exact path="/myjobs" component={MyJobsPage} />
        <PrivateRoute exact path="/w9" component={W9Page} />
        <PrivateRoute exact path="/messaging" component={MessagingPage} />
        <PrivateRoute exact path="/forum" component={DiscussionForumPage} />
        <PrivateRoute exact path="/location" component={LocationPage} />
        <PrivateRoute exact path="/files" component={FilesPage} />
        <PrivateRoute exact path="/budget" component={BudgetPage} />
        {/* <PrivateRoute exact path="/jobdetail" component={JobDetailPage} /> */}
        <PrivateRoute exact path="/bid" component={BidPage} />
        <PrivateRoute exact path="/congratulation" component={CongratulationPage} />
        <PrivateRoute exact path="/modifyjob" component={ModifyJobPage} />
        <PrivateRoute exact path="/currentbids" component={CurrentBidsPage} />
        <PrivateRoute exact path="/viewsc" component={ViewSCPage} />
        <PrivateRoute exact path="/offer" component={OfferPage} />
        <PrivateRoute exact path="/postsuccess" component={PostSuccessPage} />
        <PrivateRoute exact path="/offersent" component={OfferSentPage} />
        <PrivateRoute exact path="/viewoffer" component={ViewOfferPage} />
        <PrivateRoute exact path="/offersuccess" component={OfferSuccessPage} />
        <PrivateRoute exact path="/addroom" component={AddRoomPage} />
        <PrivateRoute exact path="/room" component={RoomPage} />
        <PrivateRoute exact path="/w9success" component={W9SuccessPage} />
        <PrivateRoute exact path="/viewimage" component={ViewImagePage} />
        <PrivateRoute exact path="/contracts" component={ContractsPage} />
        <PrivateRoute exact path="/escrow" component={EscrowPage} />
        <PrivateRoute exact path="/addgrouproom" component={AddGroupRoomPage} />
        <PrivateRoute exact path="/grouproom" component={GroupRoomPage} />
        <PrivateRoute exact path="/paynow" component={PayNowPage} />
        <PrivateRoute exact path="/oauth_redirect" component={OAuthRedirect} />
        <PrivateRoute exact path="/escrowtransfer" component={EscrowTransferPage} />
        <PrivateRoute exact path="/escrowcharge" component={EscrowChargePage} />
        <PrivateRoute exact path="/paymentstatus" component={PaymentStatusPage} />
      </Switch>
    </Router>
  );
}

export default PublicRoute;
