import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const DATA = [
  "Austin, TX Metro Area",
  "Dallas, TX Metro Area",
  "Fort Worth, TX Metro Area",
  "Houston, TX Metro Area",
  "Oklahoma City, OK Metro Area",
  "Portland, OR Metro Area",
  "San Antonio, TX Metro Area",
  "Seattle, WA Metro Area",
];

const LocationPage = () => {
  const history = useHistory();
  const {
    state: { neededService },
  } = useLocation();
  const [location, setLocation] = useState(DATA[0]);
  const [zipCode, setZipCode] = useState("");
  const [description, setDescription] = useState("");

  const onSelect = (value) => {
    if (value && value.value) setLocation(value.value);
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "zipCode") {
      setZipCode(value);
    } else if (name === "description") {
      setDescription(value);
    }
  };

  const onPress = (e) => {
    e.preventDefault();
    if (!zipCode || (/^[0-9]+$/.test(zipCode) && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode))) {
      history.push({
        pathname: "/files",
        state: { neededService, location, zipCode, description },
      });
    } else {
      toast("Please enter the valid zipcode or you can skip it.");
    }
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4 px-8">
        Please choose the location:
        <Dropdown
          options={DATA}
          onChange={onSelect}
          value={DATA[0]}
          placeholder="Please choose the location."
          className="mt-2 mb-4 w-72"
        />
        Please enter the zipcode. (optional):
        <input
          type="tel"
          className="mt-2 mb-4 p-2 w-72 border border-black-400"
          name="zipCode"
          value={zipCode}
          placeholder=""
          id="zipCode"
          onChange={(event) => onChangeHandler(event)}
        />
        <div className="text-center">Please provide a brief description of your contract job...</div>
        <textarea
          className="mt-2 p-2 w-80 md:w-96 h-36 border border-black-400"
          name="description"
          value={description}
          placeholder=""
          id="description"
          onChange={(event) => onChangeHandler(event)}
        />
        <button
          className={`w-48 ${
            description ? "bg-primary" : "bg-gray-500"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={(event) => {
            onPress(event);
          }}
          disabled={!description}
        >
          Next
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LocationPage;
