import React, { useState, useRef, useContext, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import Dropdown from "react-dropdown";
import { AuthContext } from "../providers/Authentication";
import { storage } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { database } from "../firebase";
import { FadingCircle } from "better-react-spinkit";
import avatar from "../assets/image/pdf_icon.png";

const DATA = [
  "Cabinet & Shelf Installation",
  "Cleaning Prep",
  "Door & Garage Installation",
  "Drywall & Plastering",
  "Electrical",
  "Excavation & Leveling",
  "Fencing",
  "Flooring",
  "Foundation, Cement & Concrete",
  "Framing",
  "Glass & Mirror",
  "HVAC",
  "Insulation",
  "Kitchen Appliance Installation",
  "Kitchen & Bathroom Counter Installation",
  "Lawn & Garden",
  "Masonry",
  "Painting",
  "Plumbing",
  "Post construction Cleaning",
  "Roofing",
  "Siding & Stucco",
  "Solar Installation",
];

const DATA1 = [
  "Austin, TX Metro Area",
  "Dallas, TX Metro Area",
  "Fort Worth, TX Metro Area",
  "Houston, TX Metro Area",
  "Oklahoma City, OK Metro Area",
  "Portland, OR Metro Area",
  "San Antonio, TX Metro Area",
  "Seattle, WA Metro Area",
];

const ModifyJobPage = () => {
  const {
    state: { job },
  } = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  // eslint-disable-next-line
  const [indicator, setIndicator] = useState(false);
  const [description, setDescription] = useState(job ? job.Description : "");
  const [selectedSub, setSelectedSub] = useState(
    job ? job.ServicesNeeded : DATA[0]
  );
  const [location, setLocation] = useState(job ? job.Location : DATA1[0]);
  const [zipCode, setZipCode] = useState(job ? job.ZipCode : "");
  const [file1, setFile1] = useState(job ? job.Upload.file1 : "");
  const [file2, setFile2] = useState(job ? job.Upload.file2 : "");
  const [file3, setFile3] = useState(job ? job.Upload.file3 : "");
  const [file4, setFile4] = useState(job ? job.Upload.file4 : "");
  const [file5, setFile5] = useState(job ? job.Upload.file5 : "");
  const [file6, setFile6] = useState(job ? job.Upload.file6 : "");
  const [maxBid, setMaxBid] = useState(job ? job.Payment.Overall : "45000");
  const [checked, setChecked] = useState(
    job && job.Payment.phase1 ? "second" : "first"
  );
  const [phase1, setPhase1] = useState(job ? job.Payment.phase1 : "");
  const [phase2, setPhase2] = useState(job ? job.Payment.phase2 : "");
  const [phase3, setPhase3] = useState(job ? job.Payment.phase3 : "");
  const [indicator1, setIndicator1] = useState(false);
  const [indicator2, setIndicator2] = useState(false);
  const [indicator3, setIndicator3] = useState(false);
  const [indicator4, setIndicator4] = useState(false);
  const [indicator5, setIndicator5] = useState(false);
  const [indicator6, setIndicator6] = useState(false);
  const [several, setSeveral] = useState(false);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);
  const fileInputRef6 = useRef(null);

  useEffect(() => {
    let restPayment = 0;
    if (phase1 && phase2) {
      restPayment = maxBid - phase1 - phase2;
      setPhase3("" + restPayment);
    } else {
      setPhase3("");
    }

    if (phase1 && phase2 && phase3 && restPayment > 0) {
      setSeveral(true);
    } else {
      setSeveral(false);
    }
  }, [maxBid, phase1, phase2, phase3]);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "zipCode") {
      setZipCode(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "maxBid") {
      setMaxBid(value);
    } else if (name === "phase1") {
      setPhase1(value);
    } else if (name === "phase2") {
      setPhase2(value);
    } else if (name === "phase3") {
      setPhase3(value);
    }
  };

  const handleUpload1 = () => {
    const fileInputElement = fileInputRef1.current;
    fileInputElement.click();
  };

  const handleUpload2 = () => {
    const fileInputElement = fileInputRef2.current;
    fileInputElement.click();
  };

  const handleUpload3 = () => {
    const fileInputElement = fileInputRef3.current;
    fileInputElement.click();
  };

  const handleUpload4 = () => {
    const fileInputElement = fileInputRef4.current;
    fileInputElement.click();
  };

  const handleUpload5 = () => {
    const fileInputElement = fileInputRef5.current;
    fileInputElement.click();
  };

  const handleUpload6 = () => {
    const fileInputElement = fileInputRef6.current;
    fileInputElement.click();
  };

  const handleChange = async (e, number) => {
    if (e.currentTarget.files !== null) {
      if (number === 1) {
        setIndicator1(true);
      } else if (number === 2) {
        setIndicator2(true);
      } else if (number === 3) {
        setIndicator3(true);
      } else if (number === 4) {
        setIndicator4(true);
      } else if (number === 5) {
        setIndicator5(true);
      } else {
        setIndicator6(true);
      }
      const fileUploaded = e.currentTarget.files[0];

      const fileType = fileUploaded.type || "type";
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate() +
        "-" +
        today.getTime();

      let fileUrl = "";
      if(currentUser.userType === 0) {
        await storage
          .ref("GCT")
          .child(`${currentUser.UserUid}/${date}/${fileType}-file${number}`)
          .put(fileUploaded);
        fileUrl = await storage
          .ref("GCT")
          .child(`${currentUser.UserUid}/${date}/${fileType}-file${number}`)
          .getDownloadURL();
      } else if(currentUser.userType === 2) {
        await storage
          .ref("RT")
          .child(`${currentUser.UserUid}/${date}/${fileType}-file${number}`)
          .put(fileUploaded);
        fileUrl = await storage
          .ref("RT")
          .child(`${currentUser.UserUid}/${date}/${fileType}-file${number}`)
          .getDownloadURL();
      }
      if (number === 1) {
        setFile1(fileUrl);
        setIndicator1(false);
      } else if (number === 2) {
        setFile2(fileUrl);
        setIndicator2(false);
      } else if (number === 3) {
        setFile3(fileUrl);
        setIndicator3(false);
      } else if (number === 4) {
        setFile4(fileUrl);
        setIndicator4(false);
      } else if (number === 5) {
        setFile5(fileUrl);
        setIndicator5(false);
      } else {
        setFile6(fileUrl);
        setIndicator6(false);
      }
      toast("File Saved!");
    }
  };

  const onPress = (e) => {
    e.preventDefault();
    if (!zipCode || (/^[0-9]+$/.test(zipCode) && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode))) {
    } else {
      toast("Please enter the valid zipcode or you can skip it.");
      return;
    }

    try {
      setIndicator(true);
      const key = job.JobId;
      database
        .ref(`JT/${key}`)
        .update({
          ServicesNeeded: selectedSub,
          Location: location,
          ZipCode: zipCode,
          Description: description,
          Upload: {
            file1: file1,
            file2: file2,
            file3: file3,
            file4: file4,
            file5: file5,
            file6: file6,
          },
          Payment: {
            Overall: maxBid,
            phase1: phase1,
            phase2: phase2,
            phase3: phase3,
          },
        })
        .then((res) => {
          setIndicator(false);
          toast("Update Success!");
          setTimeout(() => history.goBack(), 2000);
        })
        .catch((err) => {
          setIndicator(false);
          toast("Update Failed!");
        });
    } catch (error) {
      toast("Update Failed!");
    }
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4 px-8">
        Services Needed
        <Dropdown
          options={DATA}
          onChange={(value) => setSelectedSub(value.value)}
          value={selectedSub}
          placeholder=""
          className="mt-2 mb-4 w-80 md:w-96 mx-4"
        />
        Location
        <Dropdown
          options={DATA1}
          onChange={(value) => setLocation(value.value)}
          value={location}
          placeholder=""
          className="mt-2 mb-4 w-80 md:w-96 mx-4"
        />
        Zip Code
        <input
          type="tel"
          className="mt-2 mb-4 p-2 w-80 md:w-96 border border-black-400"
          name="zipCode"
          value={zipCode}
          placeholder=""
          id="zipCode"
          onChange={(event) => onChangeHandler(event)}
        />
        Description
        <textarea
          className="mt-2 p-2 w-80 md:w-96 h-36 border border-black-400 mb-4"
          name="description"
          value={description}
          placeholder=""
          id="description"
          onChange={(event) => onChangeHandler(event)}
        />
        Uploading Files
        <div className="flex justify-around items-center my-3">
          {file1 && file1.includes("image") && (
            <img
              src={file1}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file1.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file1} type="video/mp4" />
            </video>
          )}
          {file1.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file1 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef1}
            onChange={(e) => handleChange(e, 1)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload1(event);
            }}
          >
            {indicator1 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>

        <div className="flex justify-around items-center my-3">
          {file2 && file2.includes("image") && (
            <img
              src={file2}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file2.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file2} type="video/mp4" />
            </video>
          )}
          {file2.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file2 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef2}
            onChange={(e) => handleChange(e, 2)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload2(event);
            }}
          >
            {indicator2 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>

        <div className="flex justify-around items-center my-3">
          {file3 && file3.includes("image") && (
            <img
              src={file3}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file3.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file3} type="video/mp4" />
            </video>
          )}
          {file3.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file3 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef3}
            onChange={(e) => handleChange(e, 3)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload3(event);
            }}
          >
            {indicator3 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>

        <div className="flex justify-around items-center my-3">
          {file4 && file4.includes("image") && (
            <img
              src={file4}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file4.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file4} type="video/mp4" />
            </video>
          )}
          {file4.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file4 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef4}
            onChange={(e) => handleChange(e, 4)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload4(event);
            }}
          >
            {indicator4 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>

        <div className="flex justify-around items-center my-3">
          {file5 && file5.includes("image") && (
            <img
              src={file5}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file5.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file5} type="video/mp4" />
            </video>
          )}
          {file5.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file5 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef5}
            onChange={(e) => handleChange(e, 5)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload5(event);
            }}
          >
            {indicator5 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>

        <div className="flex justify-around items-center my-3">
          {file6 && file6.includes("image") && (
            <img
              src={file6}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {file6.includes("video") && (
            <video
              autoPlay
              muted
              loop
              className="border rounded-lg h-32 w-32"
            >
              <source src={file6} type="video/mp4" />
            </video>
          )}
          {file6.includes("pdf") && (
            <img
              src={avatar}
              alt="..."
              className="border rounded-lg h-32 w-32 object-contain"
            />
          )}
          {!file6 && <div className="border w-32 h-32 rounded-lg"></div>}
          <input
            type="file"
            ref={fileInputRef6}
            onChange={(e) => handleChange(e, 6)}
            className="hidden"
          />
          <button
            className="flex justify-center items-center h-10 bg-primary ml-4 hover:bg-opacity-70 w-32 py-2 text-white mx-auto my-4 rounded-lg focus:outline-none"
            onClick={(event) => {
              handleUpload6(event);
            }}
          >
            {indicator6 ? <FadingCircle size={16} color="#03a9f4" /> : "Browse"}
          </button>
        </div>
        Maximum Offer
        <div className="flex items-center text-primary font-bold text-2xl">
          $&nbsp;
          <input
            type="number"
            className="my-2 p-1 focus:outline-none font-bold border"
            name="maxBid"
            value={maxBid}
            placeholder=""
            id="maxBid"
            onChange={onChangeHandler}
          />
        </div>
        <div className="flex items-center mt-4">
          <input
            type="radio"
            name="first"
            value="first"
            checked={checked === "first"}
            onChange={() => setChecked("first")}
          />
          &nbsp;&nbsp;Overall Payment
        </div>
        <div className="flex items-center mt-4">
          <input
            type="radio"
            name="second"
            value="second"
            checked={checked === "second"}
            onChange={() => setChecked("second")}
          />
          &nbsp;&nbsp;Several Smaller Payments
        </div>
        <div className="flex items-center">
          Phase 1 Completion:&nbsp;&nbsp;&nbsp;
          <div className="flex items-center text-primary">
            $&nbsp;
            <input
              type="number"
              className="my-2 p-1 focus:outline-none border"
              name="phase1"
              value={phase1}
              placeholder=""
              id="phase1"
              onChange={onChangeHandler}
              disabled={checked === "second" ? false : true}
            />
          </div>
        </div>
        <div className="flex items-center">
          Phase 2 Completion:&nbsp;&nbsp;&nbsp;
          <div className="flex items-center text-primary">
            $&nbsp;
            <input
              type="number"
              className="my-2 p-1 focus:outline-none border"
              name="phase2"
              value={phase2}
              placeholder=""
              id="phase2"
              onChange={onChangeHandler}
              disabled={checked === "second" ? false : true}
            />
          </div>
        </div>
        <div className="flex items-center">
          Phase 3 Completion:&nbsp;&nbsp;&nbsp;
          <div className="flex items-center text-primary">
            $&nbsp;
            <input
              type="number"
              className="my-2 p-1 focus:outline-none border"
              name="phase3"
              value={phase3}
              placeholder=""
              id="phase3"
              onChange={onChangeHandler}
              disabled={checked === "second" ? false : true}
            />
          </div>
        </div>
        <button
          className={`w-48 ${
            !several && checked === "second" ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={(event) => onPress(event)}
          disabled={!several && checked === "second"}
        >
          Update
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ModifyJobPage;
