import React, { useContext, useState, useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import { firestore } from "../firebase";
import { useLocation, useHistory } from "react-router-dom";
import ChatMessage from "../components/ChatMessage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storage } from "../firebase";
import { ThreeBounce } from "better-react-spinkit";

const RoomPage = () => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const {
    state: { thread },
  } = useLocation();
  const [messages, setMessages] = useState([]);
  const [attachedImage, setAttachedImage] = useState("");
  const [formValue, setFormValue] = useState("");
  const [indicator, setIndicator] = useState(false);
  const fileInputRef = useRef(null);
  const messagesEnd = useRef(null);

  const handleSend = async (text) => {
    setFormValue("");

    firestore
      .collection(
        currentUser.userType === 1 ? thread.userUid : currentUser.UserUid
      )
      .doc(thread._id)
      .collection("MESSAGES")
      .add({
        text,
        createdAt: new Date().getTime(),
        user: {
          _id: currentUser.UserUid,
          email: currentUser.Email,
        },
        image: attachedImage,
      })
      .then((docRef) => {
        setAttachedImage("");
      });

    await firestore
      .collection(
        currentUser.userType === 1 ? thread.userUid : currentUser.UserUid
      )
      .doc(thread._id)
      .set(
        {
          latestMessage: {
            text,
            createdAt: new Date().getTime(),
          },
        },
        { merge: true }
      );
  };

  useEffect(() => {
    const messagesListener = firestore
      .collection(
        currentUser.userType === 1 ? thread.userUid : currentUser.UserUid
      )
      .doc(thread._id)
      .collection("MESSAGES")
      .orderBy("createdAt")
      .onSnapshot((querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => {
          const firebaseData = doc.data();

          const data = {
            _id: doc.id,
            text: "",
            createdAt: new Date().getTime(),
            ...firebaseData,
          };

          if (!firebaseData.system) {
            data.user = {
              ...firebaseData.user,
              name: firebaseData.user.email,
            };
          }

          return data;
        });
        setMessages(messages);
      });

    // Stop listening for updates whenever the component unmounts
    return () => messagesListener();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (messagesEnd) {
      setTimeout(() => messagesEnd.current.scrollIntoView({ behavior: "smooth" }), 1500);
    }
  }, [messages]);

  const handleChange = async (e) => {
    if (e.currentTarget.files !== null) {
      setIndicator(true);
      const fileUploaded = e.currentTarget.files[0];

      let timestamp = Date.now();

      if (currentUser.userType === 0) {
        await storage
          .ref("GCT")
          .child(`${currentUser.UserUid}/attachment/${timestamp}`)
          .put(fileUploaded);
        const fileUrl = await storage
          .ref("GCT")
          .child(`${currentUser.UserUid}/attachment/${timestamp}`)
          .getDownloadURL();
        setAttachedImage(fileUrl);
        toast("Image is attached.");
      } else if (currentUser.userType === 1) {
        await storage
          .ref("SCT")
          .child(`${thread.userUid}/attachment/${timestamp}`)
          .put(fileUploaded);
        const fileUrl = await storage
          .ref("SCT")
          .child(`${thread.userUid}/attachment/${timestamp}`)
          .getDownloadURL();
        setAttachedImage(fileUrl);
        toast("Image is attached.");
      } else if (currentUser.userType === 2) {
        await storage
          .ref("RT")
          .child(`${currentUser.UserUid}/attachment/${timestamp}`)
          .put(fileUploaded);
        const fileUrl = await storage
          .ref("RT")
          .child(`${currentUser.UserUid}/attachment/${timestamp}`)
          .getDownloadURL();
        setAttachedImage(fileUrl);
        toast("Image is attached.");
      }

      setIndicator(false);
    }
  };

  const handleUpload = () => {
    const fileInputElement = fileInputRef.current;
    fileInputElement.click();
  };

  return (
    <div className="w-full relative flex flex-col">
      <Navbar />
      <button
        className="flex justify-center items-center bg-primary rounded-full text-white text-center h-10 w-10 sticky top-4 left-4 flex-grow-0 flex-shrink-0"
        onClick={(event) => {
          event.preventDefault();
          history.goBack();
        }}
      >
        <i className="fa fa-arrow-left text-lg leading-lg text-white"></i>
      </button>
      <div className="flex flex-col flex-1 px-6 pb-24">
        {messages &&
          messages.map((msg) => (
            <ChatMessage
              key={msg._id}
              message={msg}
              uid={currentUser.UserUid}
            />
          ))}
      </div>
      <div ref={messagesEnd}></div>
      <div className="fixed bottom-0 w-full flex justify-center items-center px-4 py-4 bg-white">
        {indicator && <ThreeBounce size={16} color="#03a9f4" />}
        <input
          type="file"
          ref={fileInputRef}
          onChange={(e) => handleChange(e)}
          // accept=".png,.jpg,.gif,.PNG,.pdf,.PDF"
          className="hidden"
        />
        <button onClick={(e) => handleUpload(e)}>
          <i className="fa fa-paperclip text-lg leading-lg text-black opacity-75 mx-4"></i>
        </button>
        <input
          className="p-2 w-96 border border-black-400"
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="Type something here..."
        />
        <button
          className={`w-24 ${
            !formValue && !attachedImage ? "bg-gray-500" : "bg-primary"
          } text-white py-2 ml-3 rounded-full focus:outline-none`}
          onClick={() => handleSend(formValue)}
          disabled={!formValue && !attachedImage}
        >
          Send
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RoomPage;
