import React from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";

const OfferSuccessPage = () => {
  const history = useHistory();
  const {
    state: { isAccepted },
  } = useLocation();

  const onClose = (e) => {
    e.preventDefault();
    history.push("/");
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center p-4 text-2xl font-bold text-center">
        <p className="mt-4">{isAccepted ? "Congratulations," : "Oops,"}</p>
        <p className="mt-8">
          {isAccepted
            ? "Thank you for confirming job acceptance. Please check your email for additional information."
            : "You declined the job offer. Please check your email for additional information."}
        </p>
        <button
          className={`w-48 bg-primary text-white py-2 mt-8 rounded-full focus:outline-none`}
          onClick={(event) => onClose(event)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default OfferSuccessPage;
