import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import axios from "axios";
import { ThreeBounce } from "better-react-spinkit";
import { database } from "../firebase";
import { useHistory } from "react-router-dom";
import escrowSymbol from "../assets/image/escrow.jpg";
import gcAndSc from "../assets/image/gc_and_sc.jpg";
import jobCompleted from "../assets/image/job_completed.jpg";
import postPayment from "../assets/image/post_payment.jpg";
import rightArrow from "../assets/image/right_arrow.png";
import asteriskSymbol from "../assets/image/current_process.png";

const clientID = "ca_KQpRUMAxJhcvd5tAC7RTK1ZPBi3Ona4W"; //Test mode
const redirectUri = "https://subworknow-web.com/oauth_redirect";
// const redirectUri = "http://localhost:3000/escrow/oauth_redirect";

const EscrowPage = () => {
  const { currentUser } = useContext(AuthContext);
  const [indicator, setIndicator] = useState(false);
  const [terms, setTerms] = useState(false);
  const [stripeLoggedIn, setStripeLoggedIn] = useState(false);
  const [stripeAccounts, setStripeAccounts] = useState([]);
  const history = useHistory();

  const description = `SWN provides a technology platform connecting independent builders,
  general contractors, and subcontractors and wholesalers (collectively,
  “Users”) with one another. Any employment or independent contractor
  relationship entered into between or among Users is directly between
  and among the Users; SWN, is not a party to any such relationship.
  You acknowledge and agree that the relationship between you SWN, is
  limited to payment to SWN for use of the Services (where your use of
  the Services requires payment to SWN). Users are neither employees nor
  independent contractors of SWN. No joint venture,
  franchisor-franchisee, partnership or agency relationship is intended
  or created by these Terms of Use.
  The Services offered by SWN do not include those of a general
  contractor, subcontractor or wholesaler. SWN, does not in any way
  employ, supervise, manage, direct, or control the work of Users in any
  way and does not assess, warrant, or guarantee the suitability, legal
  work status, skills, experience, or abilities of any User. SWN, does
  not verify Users’ compliance with applicable laws or regulations and
  has no responsibility or liability for the acts and/or omissions of
  any User. Accordingly, in the event you transact business with other
  Users through the Services, you do so at your own risk, and you agree
  to use your own independent skill, experience, knowledge and expertise
  in doing so, without reliance on SWN or the Services for such
  purposes.
  The Services (Including the Content) are owned by SWN and are
  protected under copyright, trademark, and other applicable United
  States and international laws and treaties Without limiting the
  foregoing, the trademarks, service marks, and logos displayed on this
  site are registered and unregistered marks of SWN, and its licensors.
  You acknowledge and agree that, as between you and SWN, is and shall
  remain the sole owner of the Services and the Content, including
  without limitation, all patents, copyrights, trademarks, trade
  secrets, and other intellectual property and proprietary rights
  therein and thereto. Any User further acknowledges and agree that all
  information collected by SWN, is subject to our Privacy Policy by
  using the Services, you consent to all actions we take with respect to
  your information in compliance with our Privacy Notice.`;

  useEffect(() => {
    async function fetchData() {
      console.log("currentUser type=>", currentUser.userType);

      if (currentUser && currentUser.userType === 1) {
        setIndicator(true);

        let scStripeAccounts = [];
        const snapshot = await database.ref(`stripe_accounts`).once("value");
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            scStripeAccounts.push(child.val());
          });
        }
        setStripeAccounts(scStripeAccounts);

        setIndicator(false);
      }
    }
    fetchData();
  }, []);

  const handleAccountCheck = (userUid) => {
    return stripeAccounts.some((item) => userUid === item.userUid);
  };

  // SC sign in to the Stripe account to receive fund from SWN about any ended contract
  const signInWithStripeHandler = async (event) => {
    event.preventDefault();
    const userInfo = currentUser;
    setIndicator(true);

    if (handleAccountCheck(userInfo.UserUid) === true) {
      const snapshot1 = await database
        .ref(`stripe_accounts/${userInfo.UserUid}`)
        .once("value");
      const accountId1 = snapshot1.val().accountId;
      axios
        .post(
          "https://us-central1-subworknow.cloudfunctions.net/retriveStripeAccount",
          { accountId: accountId1 },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(function (response) {
          console.log("account retrive response=>", response);
          setIndicator(false);
          const data = response.data;
          const status = response.status;
          if (status === 200) {
            setStripeLoggedIn(true);
            let accountId = data.id;
            history.push({
              pathname: "escrowtransfer",
              state: { accountId },
            });
          } else {
            setStripeLoggedIn(false);
          }
        })
        .catch(function (error) {
          setIndicator(false);
          setStripeLoggedIn(false);
        });
    } else {
      const newPageUrl = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://connect.stripe.com/connect/default/oauth/test&client_id=${clientID}&suggested_capabilities[]=transfers&scope=read_write&redirect_uri=${redirectUri}`;
      window.open(newPageUrl, "_self");
    }
  };

  // GC pay to the SWN about any ended contract
  const gotoCharge = (event) => {
    event.preventDefault();

    history.push({
      pathname: "escrowcharge",
    });
  };

  return (
    <div className="w-full">
      <Navbar />
      {/* <div className="text-3xl font-bold text-center mt-24">Coming Soon!</div> */}
      <>
        {indicator && (
          <div className="flex justify-center items-center flex-col my-4">
            <ThreeBounce size={24} color="#03a9f4" />
            Please wait...
          </div>
        )}

        <div className="flex justify-center items-center py-4 font-bold text-2xl mt-8">
          Escrow Process
        </div>
        <div className="text-3xl font-bold text-center my-4 text-coming">Coming Soon!</div>
        <div className="flex justify-center items-center text-center text-primary font-bold my-8">
          {currentUser.userType === 1 &&
            <>
              <div className="w-40 mx-4">
                Create or Login to Stripe Payment Account
                <img src={escrowSymbol} alt="..." className="w-24 h-auto m-auto" />
              </div>
              <img src={rightArrow} alt="..." className="w-24 h-8" />
            </>
          }
          <div className="w-40 mx-4">
            Genearl Contractor Funds Job
            <img src={gcAndSc} alt="..." className="w-24 h-auto m-auto" />
          </div>
          <img src={rightArrow} alt="..." className="w-24 h-8" />
          <div className="w-40 mx-4">
            Funds Sit In Escrow Awaiting Job Completion
            <img src={escrowSymbol} alt="..." className="w-24 h-auto m-auto" />
          </div>
          <img src={rightArrow} alt="..." className="w-24 h-8" />
          <div className="w-40 mx-4">
            General Contractor Notified & Approves Job Completion
            <img src={jobCompleted} alt="..." className="w-24 h-auto m-auto" />
          </div>
          <img src={rightArrow} alt="..." className="w-24 h-8" />
          <div className="w-40 mx-4">
            Funds Released to Sub-Contractor
            <img src={postPayment} alt="..." className="w-24 h-auto m-auto" />
          </div>
        </div>
        <div className="text-3xl font-bold text-center my-4 text-coming">Current Process</div>
        {/* <div className="flex justify-center my-2">
          <img src={asteriskSymbol} alt="..." className="w-8 h-8" />
        </div> */}
        <div className="flex justify-center items-center text-center text-primary font-bold my-8">
          <div className="w-40 mx-4">
            General Contractor Notified & Approves Job Completion
            <img src={jobCompleted} alt="..." className="w-24 h-auto m-auto" />
          </div>
          <img src={rightArrow} alt="..." className="w-24 h-8" />
          <div className="w-40 mx-4">
            General Contractor Funds and Releases to Sub-Contractor Within 7 Days
            <img src={postPayment} alt="..." className="w-24 h-auto m-auto" />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <label className="mt-2">
            <input
              type="checkbox"
              name="checkboxx"
              checked={terms}
              onChange={() => setTerms(true)}
            />
            &nbsp;&nbsp;&nbsp;Explanation and Terms of Acceptance
          </label>
        </div>
        {terms && (
          <textarea
            className="mt-2 p-2 flex justify-center mx-auto w-full max-w-xl h-44 border border-black-400 mb-4"
            id="description"
            name="description"
            value={description}
            rows="10"
            cols="30"
            editable={false}
          />
        )}
        {(currentUser.userType === 0 || currentUser.userType === 2) && (
          <button
            className={`${
              terms === true ? "bg-primary" : "bg-gray-500"
            } flex justify-center mx-auto w-4/5 md:w-1/5 my-6 py-2 text-white rounded`}
            onClick={(event) => {
              gotoCharge(event);
            }}
            disabled={!terms}
          >
            Pay Now
          </button>
        )}
        {currentUser.userType === 1 && (
          <button
            className={`${
              terms === true ? "bg-primary" : "bg-gray-500"
            } flex justify-center mx-auto w-4/5 md:w-1/5 my-6 py-2 text-white rounded`}
            onClick={(event) => {
              signInWithStripeHandler(event);
            }}
            disabled={!terms}
          >
            {stripeLoggedIn === false
              ? "Stripe Account Login / SignUp"
              : "Stripe Account Connected"}
          </button>
        )}

        {(currentUser.userType === 0 || currentUser.userType === 2) && (
          <div className="flex flex-col justify-center text-center mx-auto w-full max-w-xl px-8 pb-12">
            <p className="text-lg font-bold mt-5 mb-2 underline">Current Escrow Payment Service Process</p>
            <p className="mt-3">
              1 - Selected Sub-Contractor completes job/project and notifies you within SubWorkNow.com and/or via email.
            </p>
            <p className="mt-3">
              2 - Upon satisfaction with job/project completion go to "<b className="underline">Contracts</b>" and select to "<b className="underline">End</b>" the applicable job/project.
            </p>
            <p className="mt-3">
              3 - If convenient you may then pay your Sub-Contractor via the SWN Escrow Payment tool. Your Sub-Contractor will be assessed a 2% service fee (approximately 1% banking fee & 1% SWN service fee).
            </p>
          </div>
        )}
        {currentUser.userType === 1 && (
          <div className="flex flex-col justify-center text-center mx-auto w-full max-w-xl px-8 pb-12">
            <p className="text-lg font-bold mt-5 mb-2 underline">Current SC Escrow Payment Service Process</p>
            <p className="mt-3">
              1 - <b>Register</b> or <b>Login</b> to the Strip Payment Service under the above "<b className="underline">Escrow</b>" category so any General Contractor can immediately pay for the completed services.
            </p>
            <p className="mt-3">
              2 - Upon completion of the agreed job/project go to "<b className="underline">Contracts</b>" and scroll to the applicable job/project.
            </p>
            <p className="mt-3">
              3 - Select "<b>Notify GC of Job Completion</b>".
            </p>
            <p className="mt-3">
              4 - Upon General Contractor payment and processing you will receive the agreed payment minus a 2% service fee (approximately 1% banking fee & 1% SWN service fee).
            </p>
          </div>
        )}
      </>
    </div>
  );
};

export default EscrowPage;
