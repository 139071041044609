export default function ChatMessage(props) {
  const { text, system, user, image } = props.message;
  const { uid, parentCallback } = props

  const messageClass = system
    ? "text-gray-500 text-center"
    : user && user._id !== props.uid
    ? "bg-primary text-white inline-block px-3 py-1 my-2 max-w-3xl rounded-2xl break-all"
    : "bg-gray-500 text-white inline-block px-3 py-1 my-2 rounded-2xl break-all max-w-3xl";

  const onClickImage = () => {
    parentCallback(image);
  }

  return (
    <div>
      {image && (
        <div
          className={`${user && user._id === props.uid && "flex justify-end"}`}
        >
          <img onClick={onClickImage} src={image} alt="..." className="border rounded-lg w-40 md:w-48 max-h-40 md:max-h-48 object-contain" />
        </div>
      )}
      <div
        className={`${user && user._id === props.uid && "flex justify-end"}`}
      >
        <p className={messageClass}>{text}</p>
      </div>
      {user && user._id === uid &&
        <p className="text-right text-xs">Me</p>
      }
      {user && user._id !== uid &&
        <p className="text-left text-xs">From {user.name}</p>
      }
    </div>
  );
}
