import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentStatus from '../components/PaymentStatus';
import { useHistory } from "react-router-dom";
import { database } from "../firebase";

const stripePromise = loadStripe(
  // "pk_test_dwR67p5eRjZFfEMdXVLMNNOT00hBBoU2F0"
  "pk_live_oDqX9pjmVdTR2OdwzcXgeq4s003LsTxIZO"
);

const PaymentStatusPage = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [indicator, setIndicator] = useState(false);
  const [checkedEndContracts, setCheckedEndContracts] = useState([])

  useEffect(() => {
    async function fetchData() {
      setIndicator(true);

      let tempContracts = []; 

      await database.ref("OT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            if (
              child.val().isEnded &&
              child.val().UserUid === currentUser.UserUid &&
              child.val().checkVal
            ) 
              tempContracts.push(child.val());
          });
        }
      });
      setCheckedEndContracts(tempContracts);
      
      setIndicator(false);
    }

    fetchData();
  },[])

  const onClose = (e) => {
    e.preventDefault();
    history.push("/escrow");
  };

  return (
    <div className="w-full">
      <Navbar />
      <Elements stripe={stripePromise}>
        <PaymentStatus checkedEndContracts={checkedEndContracts}/>
      </Elements>

      <button
        className={`flex justify-center w-48 bg-primary text-white mx-auto py-2 mt-8 rounded-full focus:outline-none`}
        onClick={(event) => onClose(event)}
      >
        Close
      </button>
    </div>
  )
};

export default PaymentStatusPage;